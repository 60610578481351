import React from 'react'
import ReminderList from './ReminderList'
import { useStateContext } from '../contexts/ContextProvider'
const Reminder = (props) => {
    const {bg}=useStateContext();
    return (
        <div className="m-2 p-2 md:p-3 bg-white rounded-xl border-solid border"
        
        style={{backgroundColor:bg}}>
            <div className='flex items-center'>
                <p className='text-xl font-extrabold tracking-tight text-slate-900' style={{ color: "rgb(233,71,93)" }}>Reminder+</p>
            </div>
            <p className='text-xs font-semibold tracking-tight text-slate-900'><a className="text-xs font-extrabold tracking-tight">&nbsp;</a></p>
            <div className="my-2 " style={{ border: "1px solid rgb(235,235,235)" }}></div>

            <div className="" style={{ height: "60vh" }}>
            <ReminderList/>
                
            </div>
        </div>
    )
}

export default Reminder