import React, { useEffect, useState } from 'react';
import StaticsTableTr from './StaticsTableTr';
import { useStateContext } from '../contexts/ContextProvider';
const StaticsTable = (props) => {
    const { joblog } = useStateContext();
   
    return (
        <div className="h-full overflow-y-auto text-sm">
            <table className="w-full">
                <thead className='sticky top-0 border-gray-200'>
                    <tr className=""
                        style={{ backgroundColor: "rgb(255,251,240)" }}>
                         <th className=" border-gray-400 px-4 py-2 text-left">Company</th>
          <th className=" border-gray-400 px-4 py-2 text-left">Status</th>
          <th className=" border-gray-400 px-4 py-2 text-left">Title</th>
          <th className=" border-gray-400 px-4 py-2 text-left">Type</th>
          <th className=" border-gray-400 px-4 py-2 text-left">Date</th>
                    </tr>
                </thead>
                <tbody className='mt-12'>

                    {props.controlFlag === -1
                        ? joblog.map((item, index) => (
                            <StaticsTableTr
                                key={item.teams}
                                uuid={item.uuid}
                                account={item.account}
                                teams={item.teams}
                                company={item.company}
                                status={item.status}
                                title={item.title}
                                type={item.type}
                                date={item.date}
                                round={item.round}
                            />
                        ))
                        : joblog
                            .filter(item => item.status == props.controlFlag)
                            .map((item, index) => (
                                <StaticsTableTr
                                    key={item.teams}
                                    uuid={item.uuid}
                                    account={item.account}
                                    teams={item.teams}
                                    company={item.company}
                                    status={item.status}
                                    title={item.title}
                                    type={item.type}
                                    date={item.date}
                                    round={item.round}
                                />
                            ))}
                </tbody>
            </table>
        </div>
    );
};

export default StaticsTable;
