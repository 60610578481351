import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // 导入打钩图标

const avatarList = [
    require("../data/Avatar/a1.jpg"),
    require("../data/Avatar/a2.jpg"),
    require("../data/Avatar/a3.jpg"),
    require("../data/Avatar/a4.jpg"),
    require("../data/Avatar/a5.jpg"),
    require("../data/Avatar/a6.jpg"),
    require("../data/Avatar/a7.jpg"),
    require("../data/Avatar/a8.jpg"),
    require("../data/Avatar/a9.jpg"),
    require("../data/Avatar/a10.jpg"),
    require("../data/Avatar/a11.jpg"),
    require("../data/Avatar/a12.jpg"),
    require("../data/Avatar/a13.jpg"),
    require("../data/Avatar/a14.jpg"),
    require("../data/Avatar/a15.jpg"),
    require("../data/Avatar/a16.jpg"),
    require("../data/Avatar/a17.jpg"),
    require("../data/Avatar/a18.jpg"),
    require("../data/Avatar/a19.jpg"),
    require("../data/Avatar/a20.jpg"),
];

const getRandomIndexes = () => {
    const indexes = [];
    while (indexes.length < 9) {
        const randomIndex = Math.floor(Math.random() * avatarList.length);
        if (!indexes.includes(randomIndex)) {
            indexes.push(randomIndex);
        }
    }
    return indexes;
};

const LoginAvatar = (props) => {
    const [selectedAvatars, setSelectedAvatars] = useState([]);
    const [avatarSelection, setAvatarSelection] = useState(Array(avatarList.length).fill(false));

    const handleAvatarClick = (index) => {
        const newAvatarSelection = Array(avatarList.length).fill(false);
        newAvatarSelection[index] = true;
        setAvatarSelection(newAvatarSelection);

        props.handleAvatarPathChange(avatarList[index])
    };

    const handleRandomizeClick = () => {
        const defaultIndexes = getRandomIndexes();
        setSelectedAvatars(defaultIndexes);
        setAvatarSelection(Array(avatarList.length).fill(false));
        props.handleAvatarPathChange("")
    };

    useEffect(() => {
        const defaultIndexes = getRandomIndexes();
        setSelectedAvatars(defaultIndexes);
    }, []);

    return (
        <div className="">
            <div className="grid grid-cols-3 gap-3 justify-center items-center">
                {selectedAvatars.map((index) => (
                    <div
                        key={index}
                        className={`avatar-container flex justify-center items-center ${avatarSelection[index] ? "selected" : ""
                            }`}
                        onClick={() => handleAvatarClick(index)}
                    >
                        <div className="avatar-wrapper flex flex-col justify-center items-center gap-2">
                            <Avatar
                                alt={`Avatar ${index}`}
                                src={avatarList[index]}
                                sx={{
                                    width: 64,
                                    height: 64,
                                    cursor: "pointer",
                                    transition: "transform 0.2s, box-shadow 0.2s",
                                    "&:hover": {
                                        transform: "scale(1.2)",
                                        // boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
                                    },
                                }}
                            />
                            <CheckCircleIcon
                                className={`check-icon ${avatarSelection[index] ? "visible" : "invisible"}`}
                                style={{
                                    color: "black",
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-2 w-full  flex justify-center items-center">
                <ShuffleIcon className="cursor-pointer" onClick={handleRandomizeClick} />
            </div>
        </div>
    );
};

export default LoginAvatar;