import React, { Fragment, useEffect, useState } from 'react'
import { Post, HomePageStack, Editor } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import Modal from '@mui/material/Modal';
import { useRef } from 'react';
import { Box, Button, Dialog, Typography, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
const HomePage = (props) => {
  const { posts, setPosts, userId, server } = useStateContext();
  const [cache,setCache]=useState([]);
  const postLRef = useRef(posts);
  const cacheRef = useRef();
  const [initial,setinitial]=useState(false);
  useEffect(() => {
    postLRef.current = posts;
    if(postLRef.current.length!=0){
      getMorePosts();
    }
  }, [posts]);
  useEffect(()=>{
    cacheRef.current = cache;
  },[
    cache
  ])
  
  let isLoadingMore = false;

  const handleScroll = () => {
    if (isLoadingMore) {
      return; // 如果正在加载更多内容，则不执行下面的逻辑
    }
  
    const scrollY = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const distanceFromBottom = documentHeight - (scrollY + windowHeight);
  
     
    const triggerHeight = 1;
  
    if (distanceFromBottom <= triggerHeight) {
      isLoadingMore = true;  
      updateCacheToPost()
        .then(() => {
          isLoadingMore = false;  
        })
        .catch((error) => {
          isLoadingMore = false;  
          console.error("Error loading more posts:", error);
        });
    }
  };
  
  
  const updateCacheToPost = () => {
  return new Promise((resolve) => {
    setPosts(prev => [...prev, ...cacheRef.current]);
    resolve(); // 表示成功
  });
};

  const getMorePosts=()=>{
    const formData = {
      lastPostId: postLRef.current[postLRef.current.length-1].post.uuid,
      userId: userId
    };
    return fetch(server + '/getMorePosts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        const postsList = Object.values(data);
        setCache(postsList);
      })
      .catch(error => {
        console.error(error);
      });
  }
  
  useEffect(() => {
   
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [mypostList, setMypostList] = useState([]);
  const [bookpostList, setbookpostList] = useState([]);
  const [serachpostList, setSearchpostList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const changeSidHandler = (index) => {
    props.changeSidHandler(index);
  }
  const backHandler = () => {
    props.changeSidHandler(0);

  }

  const backHandlerSearch = () => {
    props.changeSidHandler(0);
    setSearchText('');
    setSearchpostList([]);
  }
  const mypostHandler = () => {
    props.changeSidHandler(8);
    searchByAccount();
  }
  const bookmarksHandler = () => {
    props.changeSidHandler(9);
    searchBookmark();
  }
  
  const [postbtn, setPostbtn] = useState(false);
  useEffect(() => {
    setPostbtn(false);
  }, [props.sid]);

  const searchByKeywords = (keywords) => {
    let formData = {
      keywords: keywords,
      userID: userId,
    };
    fetch(server + "/searchPostByKeyword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          const postlist = Object.values(data);
          setSearchpostList(postlist);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const searchBookmark = () => {
    let formData = {
      account: userId,
    };
    fetch(server + "/findBookmarkPostByUuid", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          const postlist = Object.values(data);
          setbookpostList(postlist);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const searchByAccount = () => {
    let formData = {
      account: userId,
    };
    fetch(server + "/searchByAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          const postlist = Object.values(data);
          setMypostList(postlist);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [deletePostId, setDeletePostId] = useState();
  const [editPostId, setEditPostId] = useState();
  const deletePostHandler = (postid) => {
    setdeleteOrEdit(true);
    handleOpen();
    setDeletePostId(postid);
  }
  const editPostHandler = (postid,content) => {
    setdeleteOrEdit(false);
    seteditorContent(content);
    handleOpen();
    setEditPostId(postid);
  }
  const onDelete = () => {
    let formData = {
      post: deletePostId,
    };
    fetch(server + "/deletePost", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data == true) {
          setMypostList(prev => prev.filter(post => post.post.uuid != deletePostId));
          setPosts(prev => prev.filter(posts => posts.post.uuid != deletePostId));
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const updateContent = () => {
    let formData = {
      postID: editPostId,
      content:editorContent
    };
    fetch(server + "/updatePostContent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== null) {
       
          setMypostList(prev => {
            const updatedList = prev.map(post => {
              if (post.post.uuid == editPostId) {
                return data;  
              } else {
                return post;  
              }
            });
            return updatedList;
          });
        
          setPosts(prev => {
            const updatedPosts = prev.map(post => {
              if (post.post.uuid ==editPostId) {
                return data;  
              } else {
                return post;  
              }
            });
            return updatedPosts;
          });
        }
         else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteOrEdit, setdeleteOrEdit] = useState(false);
  const handleDelete = () => {
    onDelete();
    handleClose();
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 600,
    // height: "70vh",
    bgcolor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: 3,
    p: 4,
  };
  const [editorContent, seteditorContent] = useState(null);
  const editChangeHandler = (innerhtml) => {
    seteditorContent(innerhtml);
  }
  const handleUpdateContent=()=>{
    updateContent();
    handleClose();
  }
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='rounded-xl'
      
      >
        {deleteOrEdit ? <Box sx={style}>
          <Button variant="outlined" color="secondary" onClick={handleOpen}>
            Delete
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Delete Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this post? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box> : <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          height: "70vh",
          bgcolor: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
          borderRadius: 3,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <div className="bg-white flex flex-col items-center" style={{ height: '100%' }}>
            <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold', fontFamily: 'Arial' }}>
            Edit your content
            </Typography>
            <div className="flex-grow overflow-auto p-0 m-0 w-full" >
              <Editor editChangeHandler={editChangeHandler} htmlcontent={editorContent} />
            </div>
            <div style={{ marginTop: '1rem' }}>{/* Additional content */}</div>
            <div className="flex items-end">
               
              <button
                
              onClick={handleUpdateContent}
              >
                Save
              </button>
            </div>
          </div>
        </Box>


        }
      </Modal>
      <div className="m-0 p-0 bg-white border-l "
        style={{ display: (props.sid == 0 || props.sid == 8 || props.sid == 9 || props.sid == 11) ? "block" : "none" }}>
        <div className="m-0  flex ">
           
          <div className="h-screen relative border-r w-full md:w-2/3 max-w-600px mr-4 overflow-y-auto"
            style={{ display: props.sid === 11 ? "block" : "none" }}>
            <div class="sticky top-0 z-30 bg-white bg-opacity-70 backdrop-blur-lg">
              <div class="flex items-center justify-between px-4 py-2">
                <button onClick={backHandlerSearch} class="transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-200 text-gray-700 p-2 rounded-full">
                  Back to Homepage
                </button>

                <div class="text-lg font-semibold text-gray-800">
                  Searching
                </div>
              </div>
            </div>



            {serachpostList.length == 0 ? (<div>no data</div>) : serachpostList.map((item) => (
              <Post
                key={item.post.uuid}
                uuid={item.post.uuid}
                account={item.post.uuid}
                company={item.post.company}
                tag0={item.post.tag0}
                tag1={item.post.tag1}
                tag2={item.post.tag2}
                title={item.post.title}
                content={item.post.content}
                anonymous={item.post.anonymous}
                time={item.post.time}
                emoji0={item.post.emoji0}
                emoji1={item.post.emoji1}
                emoji2={item.post.emoji2}
                emoji3={item.post.emoji3}
                emoji4={item.post.emoji4}
                flag0={item.post.flag0}
                flag1={item.post.flag1}
                flag2={item.post.flag2}
                flag3={item.post.flag3}
                comments={item.post.comments}
                avatar={item.avatar}
                username={item.username}
                bookmark={item.post.bookmark}
                likes={item.post.likes}
                rating={item.post.rating}
              />
            ))}
          </div>

          <div className="h-screen relative border-r w-full md:w-2/3 max-w-600px mr-4 overflow-y-auto"
            style={{ display: props.sid === 9 ? "block" : "none" }}>
            <div class="sticky top-0 z-30 bg-white bg-opacity-70 backdrop-blur-lg">
              <div class="flex items-center justify-between px-4 py-2">
                <button onClick={backHandler} class="transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-200 text-gray-700 p-2 rounded-full">
                  Back to Homepage
                </button>

                <div class="text-lg font-semibold text-gray-800">
                  Bookmarks
                </div>
              </div>
            </div>



            {bookpostList.length == 0 ? (<div>no data</div>) : bookpostList.map((item) => (
              <Post
                key={item.post.uuid}
                uuid={item.post.uuid}
                account={item.post.uuid}
                company={item.post.company}
                tag0={item.post.tag0}
                tag1={item.post.tag1}
                tag2={item.post.tag2}
                title={item.post.title}
                content={item.post.content}
                anonymous={item.post.anonymous}
                time={item.post.time}
                emoji0={item.post.emoji0}
                emoji1={item.post.emoji1}
                emoji2={item.post.emoji2}
                emoji3={item.post.emoji3}
                emoji4={item.post.emoji4}
                flag0={item.post.flag0}
                flag1={item.post.flag1}
                flag2={item.post.flag2}
                flag3={item.post.flag3}
                comments={item.post.comments}
                avatar={item.avatar}
                username={item.username}
                bookmark={item.post.bookmark}
                likes={item.post.likes}
                rating={item.post.rating}
              />
            ))}
          </div>

          <div className="h-screen relative border-r w-full md:w-2/3 max-w-600px mr-4 overflow-y-auto"
            style={{ display: props.sid === 8 ? "block" : "none" }}>
            <div class="sticky top-0 z-30 bg-white bg-opacity-70 backdrop-blur-lg">
              <div class="flex items-center justify-between px-4 py-2">
                <button onClick={backHandler} className="transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-200 text-gray-700 p-2 rounded-full">
                  Back to Homepage
                </button>

                <div class="text-lg font-semibold text-gray-800">
                  My Post Tracking
                </div>
              </div>
            </div>



            {mypostList.length == 0 ? (<div>no data</div>) : mypostList.map((item) => (
              <Post
                key={item.post.uuid}
                uuid={item.post.uuid}
                account={item.post.uuid}
                company={item.post.company}
                tag0={item.post.tag0}
                tag1={item.post.tag1}
                tag2={item.post.tag2}
                title={item.post.title}
                content={item.post.content}
                anonymous={item.post.anonymous}
                time={item.post.time}
                emoji0={item.post.emoji0}
                emoji1={item.post.emoji1}
                emoji2={item.post.emoji2}
                emoji3={item.post.emoji3}
                emoji4={item.post.emoji4}
                flag0={item.post.flag0}
                flag1={item.post.flag1}
                flag2={item.post.flag2}
                flag3={item.post.flag3}
                comments={item.post.comments}
                avatar={item.avatar}
                username={item.username}
                bookmark={item.post.bookmark}
                likes={item.post.likes}
                rating={item.post.rating}
                mypost={true}
                deletePostHandler={deletePostHandler}
                editPostHandler={editPostHandler}
              />
            ))}
          </div>
          <div className=" relative border-r w-full md:w-2/3 max-w-600px mr-4 overflow-y-auto"
            style={{ display: props.sid == 0 ? "block" : "none" }}>
            {posts.length == 0 ? (<div>no data</div>) : posts.map((item) => (
              <Post
                key={item.post.uuid}
                uuid={item.post.uuid}
                account={item.post.account}
                company={item.post.company}
                tag0={item.post.tag0}
                tag1={item.post.tag1}
                tag2={item.post.tag2}
                title={item.post.title}
                content={item.post.content}
                anonymous={item.post.anonymous}
                time={item.post.time}
                emoji0={item.post.emoji0}
                emoji1={item.post.emoji1}
                emoji2={item.post.emoji2}
                emoji3={item.post.emoji3}
                emoji4={item.post.emoji4}
                flag0={item.post.flag0}
                flag1={item.post.flag1}
                flag2={item.post.flag2}
                flag3={item.post.flag3}
                comments={item.post.comments}
                avatar={item.avatar}
                username={item.username}
                bookmark={item.post.bookmark}
                likes={item.post.likes}
                rating={item.post.rating}
              />
            ))}
          </div>

          <div className=" md:w-1/3 max-w-315px mx-7 flex flex-col ">
            <div className="mt-4 border w-full p-2 rounded-lg  my-5 mt-0">
              <div className="mb-4 ">
                <div
                  className="inline-flex items-center justify-center hover:bg-stone-100 p-2  rounded-full cursor-pointer focus:outline-none text-left transition duration-300 ease-in-out transform hover:scale-105"
                  onClick={bookmarksHandler}
                  style={{ width: 'fit-content' }}
                >
                  <h3 className="text-base font-semibold">
                    Bookmarks&nbsp;&nbsp;
                    {/* <span className="text-xs text-white bg-black px-2 rounded-full">20</span> */}
                  </h3>
                </div>
              </div>

              <div>
                <div
                  onClick={mypostHandler}
                  className="inline-flex items-center justify-center hover:bg-stone-100 p-2 rounded-full cursor-pointer focus:outline-none text-left transition duration-300 ease-in-out transform hover:scale-105"
                  style={{ width: 'fit-content' }}
                >
                  <h3 className="text-base font-semibold">
                    My Posts&nbsp;&nbsp;
                    {/* <span className="text-xs text-white bg-black px-2 rounded-full">20</span> */}
                  </h3>
                </div>

              </div>
            </div>
            <HomePageStack searchText={searchText} setSearchText={setSearchText} searchByKeywords={searchByKeywords} postbtn={postbtn} setPostbtn={setPostbtn} changeSidHandler={changeSidHandler} />
          
          </div>


        </div>



      </div>
    </Fragment>
  )
}

export default HomePage
// import React, { useState } from 'react';
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';

// const steps = [
//   {
//     label: 'Select campaign settings',
//     description: `For each ad campaign that you create, you can control how much
//               you're willing to spend on clicks and conversions, which networks
//               and geographical locations you want your ads to show on, and more.`,
//   },
//   {
//     label: 'Create an ad group',
//     description:
//       'An ad group contains one or more ads which target a shared set of keywords.',
//   },
//   {
//     label: 'Create an ad',
//     description: `Try out different ad text to see what brings in the most customers,
//               and learn how to enhance your ads using features like ad extensions.
//               If you run into any problems with your ads, find out how to tell if
//               they're running and how to resolve approval issues.`,
//   },
// ];

// const HomePage = (props) => {
//   const [activeStep, setActiveStep] = useState(0);

//   const handleNext = () => {
//     setActiveStep(prevActiveStep => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep(prevActiveStep => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   return (
//         <div className="m-0 p-0 "
//             style={{ display: props.sid == 0 ? "block" : "none" }}>
//     <Box sx={{ maxWidth: 400 }}>
//       <Stepper activeStep={activeStep} orientation="vertical">
//         {steps.map((step, index) => (
//           <Step key={step.label}>
//             <StepLabel
//               optional={
//                 index === 2 ? (
//                   <Typography variant="caption">Last step</Typography>
//                 ) : null
//               }
//             >
//               {step.label}
//             </StepLabel>
//             <StepContent>
//               <Typography>{step.description}</Typography>
//               <Box sx={{ mb: 2 }}>
//                 <div>
//                   <Button
//                     variant="contained"
//                     onClick={handleNext}
//                     sx={{ mt: 1, mr: 1 }}
//                   >
//                     {index === steps.length - 1 ? 'Finish' : 'Continue'}
//                   </Button>
//                   <Button
//                     disabled={index === 0}
//                     onClick={handleBack}
//                     sx={{ mt: 1, mr: 1 }}
//                   >
//                     Back
//                   </Button>
//                 </div>
//               </Box>
//             </StepContent>
//           </Step>
//         ))}
//       </Stepper>
//       {activeStep === steps.length && (
//         <Paper square elevation={0} sx={{ p: 3 }}>
//           <Typography>All steps completed - you&apos;re finished</Typography>
//           <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//             Reset
//           </Button>
//         </Paper>
//       )}
//     </Box>
//     </div>
//   );
// };

// export default HomePage;
