import React, { Fragment } from "react";
import { useRef, useState } from "react";
const ServerWindow = (props) => {
  const [chosen, setChosen] = useState(-1);
  const [index, setIndex] = useState(-2);
  const chosenRef = useRef(null);
  const handleClick = (event) => {
    if (chosenRef.current == null) {
      chosenRef.current = event.target;
    } else {
      if (props.prevRef !== null) {
        props.prevRef.style.border = "none";
        props.prevRef.style.color = "black";
      }
      chosenRef.current = event.target;
      props.setPrevRef(event.target);
      // chosenRef.current.style.backgroundColor = "rgb(47, 105, 243)";
      chosenRef.current.style.borderLeft = "solid rgb(92,95,192) 4px";
      chosenRef.current.style.color = "rgb(92,95,192)";
    }
    setChosen(event.target.getAttribute("data-key"));
    setIndex(event.target.getAttribute("data-key"));
    // alert(props.index)
    // props.onNewServer1(event.target.getAttribute("data-key"));
    props.onNewServer1(props.index);
    props.onNewServer2(props.index);
  };
  const highlightSearchKeywords = (text, search) => {
    if (!search) return text;
    const regex = new RegExp(search, 'gi');
    return text.replace(regex, (match) => `<span style="color: rgb(233,71,93)">${match}</span>`);
  };
  return (
    <Fragment>
      <div className=" max-h-full px-4 flex items-center pl-0">
        <div className="ml-1 rounded-full w-2 h-2 bg-gray-300 flex-shrink-0 mr-1" ></div>
        <button dangerouslySetInnerHTML={{ __html: highlightSearchKeywords(props.name, props.filterText) }} data-key={props.index} onClick={handleClick} ref={chosenRef} type="button" className="truncate w-full p-1 mb-1 hover:bg-light-gray hover:text-black text-left">
        </button>
      </div>

    </Fragment>
  );
};

export default React.memo(ServerWindow);
