import React, { useEffect, useState } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../contexts/ContextProvider";

const StatisticsCard = (props) => {
  const { daysUpdate } = useStateContext();
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const formattedDateStr = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    setFormattedDate(formattedDateStr);
  }, [daysUpdate]);

  return (
    <div className="m-2 p-2 md:p-3 bg-white rounded-xl border">
      <div className='flex items-center'>
        <p className='text-xl font-extrabold tracking-tight text-slate-900'>{props.title}</p>
        <span className="ml-2">
          <TooltipComponent content="
          You'll receive updates about changes in the roles you've applied for. Every day, you'll get notifications on any new progress, status shifts, or vital details regarding these positions. So that you can act promptly on fresh opportunities or make necessary adjustments to your plans." position="BottomCenter">
            <BsExclamationCircle size={14} color="blue" />
          </TooltipComponent>
        </span>
      </div>
      <p className='text-xs font-semibold tracking-tight text-slate-900'>Inspected on:<a className="text-xs font-extrabold tracking-tight">&nbsp;{formattedDate}</a></p>
      <div className="my-2" style={{ border: "1px solid rgb(235,235,235)" }}></div>
      {props.children}
    </div>
  );
}

export default StatisticsCard;
