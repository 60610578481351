import { data } from "autoprefixer";
import React from "react";
import { createContext, useContext, useState, useEffect, useRef } from "react";
const StateContext = createContext();
const initialState = {
  chat: false,
  userProfile: false,
  notification: false,
}
const generateRandom7DigitNumber = () => {
  const min = 1000000;  
  const max = 9999999;  
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const ContextProvider = ({ children }) => {
  const [bg, setbg] = useState("white");
  let oneCode=generateRandom7DigitNumber();
  // const [server, setServer] = useState("http://404didfind.com/api");
  const [server, setServer] = useState("/api");
  // const [server, setServer] = useState("http://localhost:8080");
  const [loginState, setLoginState] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClick, setIsClick] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [internData, setInternData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [joblog, setJoblog] = useState([]);
  const [newGradData, setnewGradData] = useState([]);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(-1);
  const [teamsList, setTeamsList] = useState([]);
  const [teamsActive, setTeamsActive] = useState([]);
  const [posts, setPosts] = useState([]);
  const [daysUpdate, setDaysUpdate] = useState([]);
  const isMountedRef = useRef(false);
  const [notificationsreply, setNotificationsreply] = useState([]);
  const [notificationslikes, setNotificationslikes] = useState([]);
  const [reminderListOA, setReminderListOA] = useState([]);
  const [reminderListIV, setReminderListIV] = useState([]);
  const [trendings, setTrendings] = useState([]);
  const [tempPost, setTempPost] = useState();//use in notify
  const [notifyNum, setNotifyNum] = useState(0);
  const [cNum, setCnum] = useState(0);
  const [lNum, setLnum] = useState(0);
  const [avatarUrl, setAvatarUrl] = useState();

  const [mystatsPost, setMyStatsPost] = useState();
  const [mystatsPostName, setMyStatsPostName] = useState();
  const [email, setemail] = useState();
  const [ready, setReady] = useState(false);
  const [websConect, setWebsConect] = useState(true);
  useEffect(() => {
    let newCNum = 0;
    let newLNum = 0;
    if (notificationsreply != null&&notificationsreply.length!=0) {
      newCNum = notificationsreply.filter(item => !item.haveread).length;
      setCnum(newCNum);
    }
    if (notificationslikes != null&&notificationslikes.length!=0) {
      console.log(notificationslikes)
      newLNum = notificationslikes.filter(item => !item.notificationslikes.haveread).length;
      setLnum(newLNum);
    }

    setNotifyNum(newCNum + newLNum);
  }, [notificationsreply, notificationslikes])
  useEffect(() => {
    if (isMountedRef.current) {
      const fetchData = async () => {
        try {
     
          const fetchTeamDataPromise = fetchTeamsData();
          const fetchJobsPromise = fetchJobs();
          const fetchJoblogPromise = fetchJoblog();
          const fetchPostsPromise = fetchPosts();
          const fetchReminderPromise = fetchReminder();
          const fetchDaysUpdatePromise = fetchDaysUpdate();
          const fetchNotificationsReplyPromise = fetchNotificationsreply();
          const fetchNotificationsLikesPromise = fetchNotificationslikes();
          const fetchAvatarUrlPromise = fetchAvatarUrl();
          const fetchBasicAccountPromise = fetchBasicAccount();
         const  newLoginOnehotRecordPromise= newLoginOnehotRecord();
          const fetchTrendingPromise=fetchTrending();
          await Promise.all([
            fetchTrendingPromise,
            fetchTeamDataPromise,
            fetchJobsPromise,
            fetchJoblogPromise,
            fetchPostsPromise,
            fetchReminderPromise,
            fetchDaysUpdatePromise,
            fetchNotificationsReplyPromise,
            fetchNotificationsLikesPromise,
            fetchAvatarUrlPromise,
            fetchBasicAccountPromise,
            newLoginOnehotRecordPromise(),
           
          ]);


          setReady(true);

        } catch (error) {

          console.error(error);
        }
      };
      fetchData();
    } else {
      isMountedRef.current = true;
    }
  }, [userId]);
useEffect(()=>{
  if(loginState){

    const interval = setInterval(() => {
      if (loginState&&userId!=null) {
        fetchNotificationsreply();
        fetchNotificationslikes();
        fetchDaysUpdate();
        // fetchTrending();
        CheckLoginOnehotCode();
      } else {
        clearInterval(interval);
      }
    }, 10000);


    return () => {
      clearInterval(interval);
    };
  }
},[loginState,userId])
  
  
   
  const CheckLoginOnehotCode = () => {
     
  let formData = {
    userId: userId,
    hotcode:oneCode,
  };
  fetch(server + "/onehotCheck", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data==false) {
        setLoginState(false);
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
  const newLoginOnehotRecord = () => {
    let formData = {
      userId: userId,
      hotcode:oneCode,
    };
    fetch(server + "/onehotRecord", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
         
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const setMode = (e) => {
    setCurrentMode(e);
    // localStorage.setItem("themeMode",e.target.value);
  }
  const handleClick = (clicked) => {
    setIsClick({ ...initialState, [clicked]: true });
  }
  const fetchBasicAccount = (event) => {

    let formData = {
      userId: userId,
    };
    fetch(server + "/findAccountById", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.id != null) {
          setUserName(data.username);
          setemail(data.email);
        } else {
          setLoginState(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const fetchJobs = async () => {
  //   //
  //   let formData = {
  //     userId: userId,
  //   };
  //   //
  //   const response = await fetch(server + "/getJobs");
  //   const data = await response.json();
  //   const newArry = data.map((item) => {
  //     return {
  //       Name: item.name,
  //       Requirement: item.description,
  //       Date: item.date,
  //       Country: "USA",
  //       Link: item.link,
  //       type: item.type,
  //       teamsId: item.uuid
  //     };
  //   });
  //   setJobData(newArry);
  //   updateInterndata(newArry);
  //   updateNewGradData(newArry);
  // };
  const fetchJobs = (event) => {

    let formData = {
      userId: userId,
    };
    fetch(server + "/getJobs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        const newArry = data.map((item) => {
              return {
                Name: item.name,
                Requirement: item.description,
                Date: item.date,
                Country: "USA",
                Link: item.link,
                type: item.type,
                teamsId: item.uuid
              };
            });
            setJobData(newArry);
            updateInterndata(newArry);
            updateNewGradData(newArry);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchReminder = () => {
    const formData = {
      account: userId,
    }
    fetch(server + '/findRemindersByAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        let reminders = Object.values(data);
        const today = new Date();
        const filterCondition = (item) => {
          const deadlineDate = new Date(item.deadline);
          if (deadlineDate < today && !item.flag2) {
            return false;
          }
          return true;
        };

        reminders = reminders.filter(filterCondition);

        const filteredRemindersOA = reminders.filter(item => !item.flag && !item.flag2);
        setReminderListOA(filteredRemindersOA);
        const filteredRemindersIV = reminders.filter(item => item.flag && !item.flag2);
        setReminderListIV(filteredRemindersIV);
      })
      .catch(error => {
        console.error(error);
      });
  }
  const fetchAvatarUrl = () => {
    const formData = {
      account: userId,
    };

    fetch(server + '/findImagePathByAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then(data => {
        if (typeof data === 'string') {
          try {
            const avatarUrl = new URL(data);
            
            setAvatarUrl(avatarUrl);

          } catch (error) {
            console.error("Invalid URL:", data);
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchTrending = () => {
    let formData = {
      userId: userId
    };
    fetch(server + '/getAllTrending', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        const objList = Object.values(data);
      if(objList!=null){
        setTrendings(objList);
      }
        
      })
      .catch(error => {
        console.error(error);
      });
  }
  const fetchNotificationslikes = () => {
    const formData = {
      account: userId,
    }
    fetch(server + '/groupNotificationsByConditions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {

        const objList = Object.values(data);

      if(objList!=null&&objList.length!=0){
        setNotificationslikes(objList);
      }
        
      })
      .catch(error => {
        console.error(error);
      });
  }
  // const fetchNotificationsreply = () => {
  //   const formData = {
  //     account: userId,
  //   }
  //   fetch(server + '/findNotificationReplyDataByAccount', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(formData)
  //   }).then(response => response.json())
  //     .then(data => {
  //       const objList = Object.values(data);
  //       if(objList!=null&&objList.length!=0){
  //         setNotificationsreply(objList);
  //       }
       
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }
  const fetchNotificationsreply = () => {
    const formData = {
      account: userId,
    };
    fetch(server + '/findNotificationReplyDataByAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.length > 0) {
          const objList = Object.values(data);
          setNotificationsreply(objList);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  
  const fetchDaysUpdate = () => {
    const formData = {
      account: userId,
    }
    fetch(server + '/findDaysUpdatesByAccountAndToday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        const daysUpdateList = Object.values(data);
        setDaysUpdate(daysUpdateList);
      })
      .catch(error => {
        console.error(error);
      });
  }
  const fetchPosts = () => {
    const formData = {
      userId: userId,
    }
    fetch(server + '/getPost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        const postsList = Object.values(data);

        setPosts(postsList);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const fetchJoblog = () => {
    const formData = {
      userId: userId,
    }
    fetch(server + '/joblog', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        const joblogList = Object.values(data);
        setJoblog(joblogList);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const updateNewGradData = (newArry) => {
    // true->ng
    const filteredArray = newArry.filter(item => item.type === true);
    setnewGradData(filteredArray);
  }
  const updateInterndata = (newArry) => {
    // false->interns
    const filteredArray = newArry.filter(item => item.type === false);
    setInternData(filteredArray);
  }

  const fetchTeamsData = () => {
    const formData = {
      userId: userId,
    }
    fetch(server + '/accountTeams', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        const teamsList = Object.values(data);
        setTeamsList(teamsList);
      })
      .catch(error => {
        console.error(error);
      });
  }
  return (
    <StateContext.Provider value={{trendings, setTrendings, websConect, setWebsConect, ready, setReady, mystatsPostName, setMyStatsPostName, email, setemail, mystatsPost, setMyStatsPost, lNum, setLnum, cNum, setCnum, avatarUrl, setAvatarUrl, notifyNum, setNotifyNum, notificationslikes, setNotificationslikes, tempPost, setTempPost, notificationsreply, setNotificationsreply, daysUpdate, setDaysUpdate, reminderListIV, setReminderListIV, reminderListOA, setReminderListOA, posts, setPosts, joblog, setJoblog, bg, activeMenu, setActiveMenu, isClick, setIsClick, handleClick, screenSize, setScreenSize, currentColor, currentMode, setCurrentMode, themeSettings, setThemeSettings, setMode, loginState, setLoginState, server, setServer, internData, setInternData, userName, setUserName, userId, setUserId, teamsList, setTeamsList, newGradData, setnewGradData }}>
      {children}
    </StateContext.Provider>
  )
}
export const useStateContext = () => useContext(StateContext);

