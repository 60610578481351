import React from 'react';
import { FcFlashAuto } from 'react-icons/fc';
import { useStateContext } from '../contexts/ContextProvider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
const highlightSearchKeywords = (text, search) => {
  if (!search) return text;
  const regex = new RegExp(search, 'gi');
  return text.replace(regex, (match) => `<span style="color: #FFD100">${match}</span>`);
};
const highlightSearchKeywords2 = (text, search) => {
  if (!search) return text;
  const regex = new RegExp(search, 'gi');
  return text.replace(regex, (match) => `<span style="color: #FFD100">${match}</span>`);
};



const JobCard = (props) => {
  const { joblog,userId, loginState, setLoginState, server, setTeamsList,setJoblog } = useStateContext();
  const { jobtype,company, requirement, date, link, searchText, teamsId } = props;
  const joinHandler = () => {
    if (loginState === false) {
      setLoginState(false);
    } else {
      addNewAccountTeams();
      addJoblog();
    
    }

  };
  const updateOrInsertVoteStats = () => {
    let formData = {
      teams: props.teamsId,
    };
    fetch(server + "/updateOrInsertVoteStats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  const addJoblog = () => {
    const tempcompany= company;
    const account = userId;
    const teams = teamsId;
    const company1 = tempcompany;
    const status = 0;
    const title =requirement;
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; 
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    let type = "";
    if(jobtype){
      type="New Grad";
    }else{
      type="Interns";
    }
    let formData = {
      account: account,
      teams: teams,
      company: company1,
      status: "0",
      title:title,
      type:type,
      date:formattedDate,
      round:1,
    };
    fetch(server + "/addNewJoblog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
           
          setJoblog((prev) => [formData, ...prev]);
           
        } else {
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const addNewAccountTeams = () => {
    const account = userId;
    const teams = teamsId;
    const teamname = company;
    const details = requirement;
    let formData = {
      account: account,
      teams: teams,
      teamname: teamname,
      details: details,
     
    };
    fetch(server + "/addNewAccountTeams", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          alert('Successfully join');
          setTeamsList((prev) => [formData, ...prev]);
          updateOrInsertVoteStats();
        } else {
          alert('Already joined');

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  
  return (
    <div className="bg-gray-100 border rounded-lg p-4 m-5">
      <h3 className="text-xl font-semibold mb-2" style={{ color: "rgb(233,71,93)" }} dangerouslySetInnerHTML={{ __html: highlightSearchKeywords(company, searchText) }}></h3>
      <p className="text-gray-600 mb-2" dangerouslySetInnerHTML={{ __html: highlightSearchKeywords2(requirement, searchText) }}></p>
      <p className="text-gray-600 mb-2">Publication Time:&nbsp; <a className="">{date}</a></p>
      <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="hover:underline"
      style={{
        color:"rgba(0, 149, 182)"
      }}
    >
     Explore more...
    </a>
      <div className="border-t border-gray-300 pt-2">
        <div className="flex justify-end mt-4">
          {/* <a href={link} target="_blank" className="font-medium text-gray-600 hover:text-blue-500 py-2 px-4 rounded-md mr-2 hover:underline">Join Community</a> */}
          {/* <Button href={link} target="_blank" style={{
            color: "rgb(75 85 99)"
          }}>Know more...</Button> */}
          {/* <button class=" hover:text-white py-2 px-4 rounded-md transition-colors duration-300 ease-in-out inline-flex items-center group"
          >
            <span class=" mr-1 transform transition-transform duration-300 ease-in-out scale-40 group-hover:scale-150"
            >
              <FcFlashAuto className="w-6 h-6 border-white" />
            </span>
          </button> */}
           {/* <button
            onClick={joinHandler}
            className="bg-gray-300 text-gray-600 py-2 px-4 rounded-md ml-2 hover:bg-gray-400 transition-colors duration-300 ease-in-out"
          >
            Join Community
          </button>  */}
           {/* <Button onClick={joinHandler} style={{
            color: "rgb(75 85 99)"
          }}>Join Community</Button>  */}
           <button
  onClick={joinHandler}
  style={{
    background: "rgba(0, 149, 182)",
    color: "#FFFFFF",
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background 0.3s, transform 0.2s, box-shadow 0.2s",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  }}
  className="text-base font-bold hover:bg-blue-600 active:bg-blue-400 transform hover:scale-105 hover:shadow-md"
>
  Join Community
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "4px",
      background: "rgba(255, 255, 255, 0.3)",
      boxShadow: "inset 0 0 8px rgba(255, 255, 255, 0.6)",
      pointerEvents: "none",
    }}
  ></div>
</button>


        </div>
      </div>
    </div>
  );
};

export default JobCard;
