import React, { useState, } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StatisticsTag from './StatisticsTag';
import StatisticsTagItem from './StatisticsTagItem';
import { useStateContext } from '../contexts/ContextProvider';
import Badge from '@mui/material/Badge';
import { Gi3DMeeple } from "react-icons/gi";
function StatsTab() {
    const [value, setValue] = useState(0);
    const { daysUpdate } = useStateContext();

    const filteredDataOA = daysUpdate.filter(item => item.oa !== 0);
    const sortedDataOA = filteredDataOA.sort((a, b) => b.oa - a.oa);

    const filteredDataVI = daysUpdate.filter(item => item.vi !== 0);
    const sortedDataVI = filteredDataVI.sort((a, b) => b.vi - a.vi);

    const filteredDataOffer = daysUpdate.filter(item => item.offer !== 0);
    const sortedDataOffer = filteredDataOffer.sort((a, b) => b.offer - a.offer);

    const filteredDataReject = daysUpdate.filter(item => item.reject !== 0);
    const sortedDataReject = filteredDataReject.sort((a, b) => b.reject - a.reject);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const CustomTabPanel = ({ children, value, index, ...other }) => (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );

    return (
        <div className='h-full w-full flex flex-col m-0 p-0'>
            <div className="">
                <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        
                        {/* <Tab label="Online Assessment" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                        <Tab label="Virtual Interview" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        <Tab label="Offer Stage" id="simple-tab-2" aria-controls="simple-tabpanel-2" />
                        <Tab label="Reject" id="simple-tab-2" aria-controls="simple-tabpanel-2" /> */}
                    <Tab
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                      Online Assessment
                                    <Badge badgeContent={sortedDataOA.length} color="primary">
                                    <Gi3DMeeple style={{ opacity: 0 }} />
                                    </Badge>
                                </div>
                            }
                            id="simple-tab-0"
                            aria-controls="simple-tabpanel-0"
                        />
                        <Tab
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Virtual Interview
                                    <Badge badgeContent={sortedDataVI.length} color="primary">
                                    <Gi3DMeeple style={{ opacity: 0 }} />
                                    </Badge>
                                </div>
                            }
                            id="simple-tab-1"
                            aria-controls="simple-tabpanel-1"
                        />
                        <Tab
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                     Offer Stage
                                    <Badge badgeContent={sortedDataOffer.length} color="primary">
                                    <Gi3DMeeple style={{ opacity: 0 }} />
                                    </Badge>
                                </div>
                            }
                            id="simple-tab-2"
                            aria-controls="simple-tabpanel-2"
                        />
                        <Tab
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                     Reject
                                    <Badge badgeContent={sortedDataReject.length} color="primary">
                                    <Gi3DMeeple style={{ opacity: 0 }} />
                                    </Badge>
                                </div>
                            }
                            id="simple-tab-3"
                            aria-controls="simple-tabpanel-3"
                        />
                    </Tabs>
                </Box>
            </div>

            <div className=" overflow-auto p-0 m-0">
                <CustomTabPanel value={value} index={0}>
                    <StatisticsTag title={"Online Assessment"} />
                    {sortedDataOA.map(item => (
                        <StatisticsTagItem
                            key={item.uuid}
                            name={item.company}
                            details={item.details}
                            progress={item.oa}
                            num={item.oa}
                        />
                    ))}
    
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <StatisticsTag title={"Virtual Interview"} />
                    {sortedDataVI.map(item => (
                        <StatisticsTagItem
                            key={item.uuid}
                            name={item.company}
                            details={item.details}
                            progress={item.vi}
                            num={item.vi}
                        />
                    ))}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <StatisticsTag title={"Offer Stage"} />
                    {sortedDataOffer.map(item => (
                        <StatisticsTagItem
                            key={item.uuid}
                            name={item.company}
                            details={item.details}
                            progress={item.offer}
                            num={item.offer}
                        />
                    ))}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <StatisticsTag title={"Reject"} />
                    {sortedDataReject.map(item => (
                        <StatisticsTagItem
                            key={item.uuid}
                            name={item.company}
                            details={item.details}
                            progress={item.reject}
                            num={item.reject}
                        />
                    ))}
                </CustomTabPanel>
            </div>


        </div>
    );
}

export default StatsTab;
