import React from 'react';

const SpecificationJoin = () => {
  return (
    <div className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-lg shadow-md p-6 text-white">
      <h2 className="text-3xl font-semibold mb-4">Join the Community After Applying!</h2>
      {/* <p className="text-lg leading-6 mb-4">
        Embark on an exciting journey with others! 🚀
      </p> */}
      <ul className="list-disc ml-6 text-lg">
        <li className="mb-4 flex items-center">
          
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
           
           Engage in discussion group for this position
        </li>
        <li className="mb-4 flex items-center">
          
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
           
          Receive information updates about this position
        </li>
      </ul>
    </div>
  );
};

export default SpecificationJoin;
