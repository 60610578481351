import React, { useState, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { useEffect } from 'react';
const Editor = (props) => {
  const editorRef = useRef(null);
  const [quill, setQuill] = useState(null);
  const [editorContent, setEditorContent] = useState('');
  const userId = 'your_user_id'; 
  const server = 'your_server_url';

  React.useEffect(() => {
    if (editorRef.current) {
      const newQuill = new Quill(editorRef.current, {
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            ['image', 'code-block']
          ]
        },
        placeholder: 'Compose an epic...',
        theme: 'snow'
      });
      
      newQuill.on('text-change', () => {
        props.editChangeHandler(newQuill.root.innerHTML);
      });
      setQuill(newQuill);
    }
  }, []);

  

  const handleImageClick = (e) => {
    if (e.target.tagName === 'IMG') {
      const imageUrl = e.target.getAttribute('src');
      window.open(imageUrl, '_blank');
    }
  };
  useEffect(() => {
     
    if (quill && props.htmlcontent != null) {
      quill.root.innerHTML = props.htmlcontent;
    }
  }, [quill]);

  return (
    <div className='p-0 m-0 h-full'>
      <div ref={editorRef} onClick={handleImageClick} />

      <style>
        {`
          .ql-editor img {
            max-width: 100%;
            height: auto;
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

export default Editor;
