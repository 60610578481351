import React, { Fragment,useState } from 'react'
import Button from '@mui/material/Button';
import { useStateContext } from '../contexts/ContextProvider';
const PostCommentSecond = (props) => {
    const [commentText, setCommentText] = useState('');
    const { avatarUrl,userId,server,userName } = useStateContext();
    
    const handleCommentChange = (event) => {
        setCommentText(event.target.value);
    };

    const handlePostComment = () => {
        setCommentText('');
    };
    const addSecondComment = () => {
        if (commentText.trim() !== "") {
        let formData = {
          account: userId,
          post: props.post,
          parent:props.parent,
          content:commentText,
          rate:0,
          original:props.original,
        };
        fetch(server + "/newPostReply", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
             
              setCommentText("");
              const newReply = {
                account: userId,
                content: commentText,
                parent: props.parent,
                post: props.post,
                rate: 0,
                replyid: props.parent,
                time: new Date(), 
                sender: userName,
                avatar:avatarUrl,
              };
              props.addReply(newReply);
              props.handleCommentClick();
              props.comentAddHandler();
            } else {
              
            }
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
    return (
        <Fragment>
            <div className="mt-3 border  rounded-lg">
                    <textarea
                        placeholder="Write a reply..."
                        className="w-full p-2 bg-white rounded-md border border-gray-300 focus:outline-none"
                        value={commentText}
                        onChange={handleCommentChange}
                    ></textarea>
                     
                    <Button variant="text" onClick={addSecondComment}>Post Reply</Button>
                </div>
        </Fragment>
    )
}

export default PostCommentSecond