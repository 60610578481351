import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useRoutes, useLocation } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FirstTime,Navbar, Footer, Sidebar, ThemeSettings, UserProfile } from "./components";
import {
  HomePage,
  Calendar,
  Kanban,
  Interns,
  NewGrads,
  Login,
  Profile,
  Community,
  Account,
  Notifications,
} from "./pages";
import { useStateContext, loginState } from "./contexts/ContextProvider";
import "./App.css";
import Mystat from "./pages/Mystat";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SnackbarContent from "@mui/material/SnackbarContent";

import MuiAlert, { AlertProps } from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const App = () => {
  const { websConect, setWebsConect, ready, setReady, bg, activeMenu, themeSettings, setThemeSettings, currentMode, loginState } = useStateContext();
  const action = (
    <Button color="secondary" size="small">
      lorem ipsum dolorem
    </Button>
  );
  const [prevSid, setPrevSid] = useState(0);
  const changeSidHandler = (newSid) => {
    setPrevSid(newSid);
  };

  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [count, setcount] = useState(0);
  useEffect(() => {
    if (websConect == true) {
      if (count != 0) {
        setShowInfoAlert(true);
        const timer = setTimeout(() => {
          setShowInfoAlert(false);
        }, 3000);
        return () => {
          clearTimeout(timer);
        };
      }
      setcount(prev => prev + 1);
    }
  }, [websConect]);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""} style={{ backgroundColor: bg }}>
      <BrowserRouter>
        {!loginState && <Login />}
        {loginState && (
          <div className="flex relative dark:bgmain-dark-bg">
            {showInfoAlert || !websConect ? (
              <div className="fixed top-0 left-0  w-full flex justify-center" style={{ zIndex: 999, pointerEvents: 'none' }}>
                <div className="p-4 rounded ">
                  <Stack spacing={2} sx={{ maxWidth: 600 }}>
                    {!websConect && <Alert severity="warning">The server experienced an outage due to excessive requests. The system is currently undergoing an automatic reboot, and we will reconnect shortly.</Alert>}
                    {showInfoAlert && <Alert severity="info">Reconnection successful!</Alert>}
                  </Stack>
                </div>
              </div>
            ) : null}

            {activeMenu ? (
              <div className="w-72 fixed dark:bg-secondary-dark-bg bg-white" style={{ backgroundColor: bg }}>
                <Sidebar changeSidHandler={changeSidHandler} />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">Sidebar changeSidHandler={changeSidHandler} </div>
            )}
            <div className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${activeMenu ? "md:ml-72" : "flex-2"}`}>
              <div className="h-full">
                {themeSettings && <ThemeSettings />}
                <HomePage sid={prevSid} changeSidHandler={changeSidHandler} />
                <Notifications sid={prevSid} />
                <NewGrads sid={prevSid} />
                <Interns sid={prevSid} />
                <Calendar sid={prevSid} />
                <Kanban sid={prevSid} />
                <Mystat sid={prevSid} />
                <Profile sid={prevSid} />
                <Community sid={prevSid} />
                <Account sid={prevSid} />
              </div>
            </div>
          </div>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
// import React from 'react';

// // This imports the functional component from the previous sample.
// import { VideoJS } from './components';

// const App = () => {
//   const playerRef = React.useRef(null);

//   const videoJsOptions = {
//     autoplay: true,
//     controls: true,
//     responsive: true,
//     fluid: true,
//     sources: [{
//       src: 'https://media.w3.org/2010/05/sintel/trailer.mp4',
//       type: 'video/mp4'
//     }]
//   };

//   const handlePlayerReady = (player) => {
//     playerRef.current = player;

//     // You can handle player events here, for example:
//     player.on('waiting', () => {
//       // videojs.log('player is waiting');
//     });

//     player.on('dispose', () => {
//       // videojs.log('player will dispose');
//     });
//   };

//   return (
//     <>
//       <div>Rest of app here</div>
//       <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
//       <div>Rest of app here</div>
//     </>
//   );
// }
// export default App;