
import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { LoginAvatar, LoadingWait } from "../components";
import { BsCheckCircleFill } from "react-icons/bs";
import CircularProgress from '@mui/material/CircularProgress';
const Login = () => {
  const { server } = useStateContext();
  const { setLoginState, setUserName, setUserId, email, setemail } = useStateContext();
  const [login, setLogin] = useState(true);
  const [reset, setReset] = useState(false);
  const [tempID, settempID] = useState(null);
  const [registerEmail, setregisterEmail] = useState();
  const [registerPass, setregisterPass] = useState();
  // handleLogin
  const handleLogin = (event) => {

    event.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    let formData = {
      email: email,
      password: password,
    };
    fetch(server + "/searchObject", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.id !== -1) {
          if (isRemember) {
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("userId", data.id);
          }
          setLoginState(true);
          setUserName(data.username);
          setUserId(data.id);
          setemail(data.email);
        } else {
          setLoginState(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  window.addEventListener("load", () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const userId = localStorage.getItem("userId");

    if (isLoggedIn === "true" && userId) {

      setLoginState(true);
      setUserId(userId);
    }
  });
  // handleRegister
  const [showAvatarChose, setShowAvatarChose] = useState(false);
  const handleRegister = (event) => {
    event.preventDefault();
    const username = document.getElementById("usernameR").value;
    const email = document.getElementById("emailR").value;
    const password = document.getElementById("passwordR").value;
    const invitationCode=document.getElementById("invitation").value;
    const formData = {
      invitation:invitationCode,
      username: username,
      email: email,
      password: password,
    };

    fetch(server + "/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.id != null && data.id != -1) {
          settempID(data.id);
          setShowAvatarChose(true);
          setregisterEmail(email)
          setregisterPass(password)
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("userId", data.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = () => {
    setLogin((prev) => !prev);
    setReset(false);
  };

  const handleReset = () => {
    setLogin(false);
    setReset(true);
  };

  const [emailreset, setemailreset] = useState();
  const resetPasswordHandler = () => {

    if (validateEmail(emailreset)) {
      resetPassword();
    }
  }
  const [code, setCode] = useState(false);
  const [verifycode, setverifycode] = useState("");
  const [pass, setpass] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [avatarPath, setAvatarPath] = useState("");
  const [isRemember, setIsRemember] = useState(true);
  const rememberHandler = (event) => {
    setIsRemember(prev => (!prev))
  }
  const handleAvatarPathChange = (value) => {
    setAvatarPath(value);
  }

  const handleSubmitAvatar = async () => {
    const formData = new FormData();
    formData.append('account', tempID);
    try {
      const response = await fetch(avatarPath);
      const blobData = await response.blob();
      formData.append('image', blobData, 'avatar.jpg');
      const uploadResponse = await fetch(server + '/updateOrInsertAccountImage', {
        method: 'POST',
        body: formData,
      });

      if (uploadResponse.ok) {

        let formData = {
          email: registerEmail,
          password: registerPass,
        };
        fetch(server + "/searchObject", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((response) => response.json())
          .then((data) => {

            if (data.id !== -1) {
              if (isRemember) {
                localStorage.setItem("isLoggedIn", "true");
                localStorage.setItem("userId", data.id);
              }
              setLoginState(true);
              setUserName(data.username);
              setUserId(data.id);
              setemail(data.email);
            } else {
              setLoginState(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // alert('error');
      }
    } catch (error) {
      alert('upload error: ' + error);
    }
  };


  const nextHandler = () => {
    let formData = {
      email: emailreset,
      verifycode: verifycode,
    };
    fetch(server + "/verifyResetcode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {

          setpass(true);
        } else {
          alert("try again")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const finalHandler = () => {
    let formData = {
      email: emailreset,
      newPass: newPass
    };
    fetch(server + "/setNewPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setLogin((prev) => !prev);
          setReset(false);

        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [invitation,setInvitation]=useState(false);
  const handlerInvitation=()=>{
   setInvitation(true)
  }
  const resetPassword = () => {

    let formData = {
      email: emailreset,
    };
    fetch(server + "/resetPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCode(true);
        } else {
          alert("We have no records for this email.")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const emailresetChangeHandler = (event) => {
    const newValue = event.target.value;
    setemailreset(newValue);
  };

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  return (
    <div className="min-h-screen flex justify-center items-center relative">

      <div className="absolute w-full h-full top-0 left-0 z-0">
        {/* <div className="absolute w-45 h-45 top-1/4 left-1/4 transform rotate-45">
          <div className="w-full h-full bg-red-600 opacity-70 rounded-full flex justify-center items-center">
            <span className="text-white text-xl font-semibold">
              Oops!
              <br />
              Error 404
            </span>
          </div>
        </div> */}

        <div className="absolute w-64 h-64 bg-white hover:opacity-60 opacity-0 p-4 rounded-full top-1/4 right-1/4 transform -rotate-45">
          <div className="w-full h-full flex flex-col justify-center items-center">
            <span className="text-red-500 text-4xl font-bold mb-4">Error</span>
            <span className="text-gray-700 text-lg">
              <span className="text-blue-500">Line 404:</span> <br />
              <span className="text-red-500">SyntaxError:</span> Unexpected humor expected.
            </span>
          </div>
        </div>

      </div>

      <div className="w-96 rounded-lg shadow-xl bg-white p-8 z-10">
        <h1 className="text-center text-3xl font-bold mb-4 text-black">404did<span className="text-red-500">find</span></h1>

        {login && !reset && (
          <div>
            <h2 className="text-center text-xl font-semibold mb-6">Login</h2>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <label className="block mb-2">Email</label>
                <input
                  id="email"
                  className="w-full border rounded-md p-2"
                  type="email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Password</label>
                <input
                  id="password"
                  className="w-full border rounded-md p-2"
                  type="password"
                  required
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                {/* <label>
                  <input checked={isRemember} type="checkbox" className="mr-2" onChange={rememberHandler}/>
                  Remember me
                </label> */}
                <a href="#" className="text-blue-500" onClick={handleReset}>
                  Forgot Password?
                </a>
              </div>
              <button
                type="submit"
                className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 rounded"
              >
                Login
              </button>
              <div className="text-center mt-4">
                <p>
                  Don't have an account?{" "}
                  <a href="#" className="text-blue-500" onClick={handleChange}>
                    Sign up today
                  </a>
                </p>
              </div>
            </form>
          </div>
        )}

        {!login && !reset && (
          <div>
            {showAvatarChose == false ? <h2 className="text-center text-xl font-semibold mb-6">Registration</h2> :
              <h2 className="text-center text-xl font-semibold mb-6">Great!Choose an avatar for yourself</h2>}
            {showAvatarChose == false ? <form onSubmit={handleRegister}>
              <div className="mb-4">
                <label className="block mb-2">Username</label>
                <input
                  id="usernameR"
                  className="w-full border rounded-md p-2"
                  type="text"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Email</label>
                <input
                  id="emailR"
                  className="w-full border rounded-md p-2"
                  type="email"
                  required
                />
              </div>
              <div className="mb-4">
                <div className="flex items-center">
                  <label className="block mb-2 mr-2">Invitation code(optional)</label>
                 
                </div>
                <input
                  id="invitation"
                  className="w-full border rounded-md p-2"
                  // onBlur={handlerInvitation}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Password</label>
                <input
                  id="passwordR"
                  className="w-full border rounded-md p-2"
                  type="password"
                  required
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                {/* <label>
                  <input type="checkbox" className="mr-2" />
                  Agree to the terms & conditions
                </label> */}
              </div>
              <button
                type="submit"
                className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 rounded"
              >
                Register
              </button>
              <div className="text-center mt-4">
                <p>
                  Already have an account?{" "}
                  <a href="#" className="text-blue-500" onClick={handleChange}>
                    Login
                  </a>
                </p>
              </div>
            </form> : <LoginAvatar handleAvatarPathChange={handleAvatarPathChange} />}
            {showAvatarChose == false ? null : <button
              type="submit"
              className={`mt-2 w-full bg-black hover:bg-gray-800 text-white font-bold py-2 rounded ${avatarPath ? "" : "pointer-events-none opacity-50"}`}
              disabled={!avatarPath}
              onClick={handleSubmitAvatar}
            >
              done
            </button>}
          </div>
        )}

        {reset && (
          <div>
            <h2 className="text-center text-xl font-semibold mb-6">Forgot Password?</h2>
            <p className="text-gray-400 text-sm">Reset password in two quick steps</p>
            <form>
              <div className="mb-4">
                {pass == false ? (
                  <div>
                    {!code ? (
                      <label className="block mb-2">Email</label>
                    ) : (
                      <label className="block mb-2">Enter verification code...</label>
                    )}
                  </div>
                ) : <label className="block mb-2">Great, now set your new password.</label>}

                {pass == false ? (
                  <div>
                    {!code ? (
                      <input
                        className="w-full border rounded-md p-2"
                        type="email"
                        value={emailreset}
                        onChange={emailresetChangeHandler}
                        required
                      />
                    ) : (
                      <input
                        className="w-full border rounded-md p-2"
                        value={verifycode}
                        onChange={(event) => setverifycode(event.target.value)}
                        required
                      />
                    )}
                  </div>
                ) : (<input
                  className="w-full border rounded-md p-2"
                  value={newPass}
                  onChange={(event) => setNewPass(event.target.value)}
                  required
                />)}



              </div>
              {pass == false ? (
                <div>
                  {!code ? (
                    <button
                      className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 rounded"
                    // onClick={resetPasswordHandler}
                    >
                      Reset password
                    </button>
                  ) : (
                    <button
                      className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 rounded"
                      onClick={nextHandler}
                    >
                      Next
                    </button>
                  )}
                </div>
              ) : <button
                className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 rounded"
                onClick={finalHandler}
              >
                Confirm
              </button>}


              <div className="text-center mt-4">
                <p>
                  <a href="#" className="text-black hover:underline" onClick={handleChange}>
                    Back
                  </a>
                </p>
              </div>
            </form>
          </div>
        )}


      </div>
    </div>

  );

};

export default Login;
