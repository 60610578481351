import React, { Fragment } from "react";
import { format } from "date-fns";
import { FiCornerUpLeft } from "react-icons/fi";
import { FcUndo, FcLike, FcLikePlaceholder } from "react-icons/fc";
import { useState } from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Avatar } from '@mui/material';
import { DateTime } from 'luxon';
const Message = (props) => {
  const [showButton, setShowButton] = useState(false);
  const isoDateTimeString = props.time;
const dateObject = new Date(isoDateTimeString);

function formatBackendTime(backendTime) {
  
  const dateTime = DateTime.fromISO(backendTime);

   
  const localDateTime = dateTime.toLocal();

   
  const formattedDateTime = localDateTime.toFormat('MM/dd/yyyy, h:mm a');

  return formattedDateTime;
}

const formattedDateTime = formatBackendTime(props.time);

 

  return (
    <Fragment>
      {props.iff ? (
        props.isImage ? (
          <div className="flex mt-2 mb-2">
            {/* <div className="w-10 h-10  rounded-full bg-red-400"></div> */}
            <div className="flex-1">
              <div className="text-xs pl-2 text-gray-500 mb-1 max-w-[500px]">
                <span className="overflow-hidden pr-2 text-sm whitespace-nowrap text-overflow-ellipsis max-w-[100px]"
                  style={{ color: "rgb(118,190,219)" }}>
                  {props.sendername.length > 10
                    ? props.sendername.slice(0, 10) + "..."
                    : props.sendername}
     
                </span>
                {formattedDateTime}
              </div>
              <div className="m-2 mt-0 inline-block max-w-[300px] border rounded-lg overflow-hidden shadow-xl">
                <img src={props.text} alt="sentImage" className="w-full h-auto" />
              </div>


            </div>
          </div>

        ) : (
          <div className="flex mt-2 mb-2"
            onMouseEnter={() => {
              // setShowButton(true);
            }}
            onMouseLeave={() => {
              // setShowButton(false);
            }}>

            <div className="flex-1">
            {/* <Avatar alt="User Avatar" src={""} sx={{ width: 24, height: 24 }} /> */}
              <div className="flex items-center">
              {/* <Avatar alt="User Avatar" src={""} sx={{ width: 24, height: 24 }} />  */}
              <div>
              <div className="text-xs pl-2 text-gray-500 mb-1 max-w-[500px]">
                <span className="overflow-hidden pr-2 text-sm whitespace-nowrap text-overflow-ellipsis max-w-[100px]"
                  style={{ color: "rgb(118,190,219)" }}>
                  {props.sendername.length > 10
                    ? props.sendername.slice(0, 10) + "..."
                    : props.sendername}
                </span>
                {formattedDateTime}
              </div>
              <div className="p-1 m-2 mt-0 mb-0 inline-block max-w-[300px] "
                style={{
                  borderLeft: "3px solid rgb(118,190,219)",
                }}>
                <div className="text-sm overflow-hidden whitespace-pre-wrap break-words">
                {props.text}
              </div>
              </div>
              </div>

              </div>

              {/* <div className="flex ">
                <button
                  type="button"
                  style={{
                    color: "rgb(69, 71, 140)",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-sm ml-2 mt-0 p-1 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FcUndo style={{ fontSize: "12px" }} />
                </button>
                <button
                  type="button"
                  style={{
                    color: "rgb(69, 71, 140)",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-sm ml-2 mt-0 p-1 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FcLikePlaceholder style={{ fontSize: "12px" }} />

                </button>

              </div> */}

            </div>
          </div>)) : (
        props.isImage ? (
          <div className="flex mt-2 mb-2 justify-end ">
            <div className="m-2 mt-0 inline-block max-w-[300px] border rounded-lg overflow-hidden shadow-xl">
              <img src={props.text} alt="sentImage" className="w-full h-auto" />
            </div>
          </div>

        ) : (
          <div className="flex mt-2 mb-2 justify-end "
            onMouseEnter={() => {
              // setShowButton(true);
            }}
            onMouseLeave={() => {
              // setShowButton(false);
            }}>
            {showButton && (
              <button
                type="button"
                style={{
                  color: "rgb(69, 71, 140)",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="text-sm p-1 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FcUndo style={{ fontSize: "12px" }} />
              </button>
            )}
            <div className="p-1 m-2 mt-0  inline-block max-w-[300px]"
              style={{
                borderLeft: "3px solid rgb(235,78,98)",
                // color: "white"
              }}>

              <div className="text-sm overflow-hidden whitespace-pre-wrap break-words">
                {props.text}
              </div>
            </div>
          </div>
        ))}
    </Fragment>
  );
};

export default Message;
