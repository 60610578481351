import React from 'react';
import { FaRegClock, FaRegStar, FaRegSmile, FaUsers } from 'react-icons/fa';
import { useStateContext } from '../contexts/ContextProvider';
const StaticsTableRight = ({ positionStats }) => {
    const {mystatsPostName,setMyStatsPostName}=useStateContext();
    const getIVExperienceDescription = (level) => {
        const experienceMapping = {
          1: 'Very Poor',
          2: 'Poor',
          3: 'Fair',
          4: 'Good',
          5: 'Excellent',
        };
      
        const clampedLevel = Math.min(Math.max(level, 1), 5);
      
        return experienceMapping[clampedLevel];
      };
    const getOADifficultyDescription = (level) => {
        const difficultyMapping = {
          1: 'Extremely Challenging',
          2: 'Quite Difficult',
          3: 'Somewhat Challenging',
          4: 'Easy',
          5: 'Super Easy',
        };
      
        const clampedLevel = Math.min(Math.max(level, 1), 5);
      
        return difficultyMapping[clampedLevel];
      };
    return (
        <div className="  p-4   h-full">
            {mystatsPostName!=null? <h3 className="text-xl font-semibold mb-4 text-gray-800">{mystatsPostName}</h3>:
            <h3 className="text-xl font-semibold mb-4 text-gray-800">
            Click on the company name to view detailed data
        </h3>
        
            }
             
            
   
               {/* <span className="text-base font-semibold mb-4 text-gray-800">{mystatsPostName}</span> */}
                    <div className="flex items-center mb-3">
                        <FaRegClock className="w-6 h-6 mr-2 text-gray-600" />
                        <p className="text-sm text-gray-700">
                            <span className="font-medium">Average Days to receive OA:&nbsp;</span>
                            {positionStats==null || positionStats.daystooa == null
                                ? "No data available at the moment."
                                : `${positionStats && positionStats.daystooa} days`}

                        </p>
                    </div>
                    <div className="flex items-center mb-3">
                        <FaRegStar className="w-6 h-6 mr-2 text-gray-600" />
                        <p className="text-sm text-gray-700">
                            <span className="font-medium">Online Assessment Difficulty:&nbsp;</span>
                            {positionStats==null || positionStats.oadifficult == null
                                ? "No data available at the moment."
                                : getOADifficultyDescription(positionStats.oadifficult)}

                        </p>
                    </div>
                    <div className="flex items-center mb-3">
                        <FaRegSmile className="w-6 h-6 mr-2 text-gray-600" />
                        <p className="text-sm text-gray-700">
                            <span className="font-medium">Interview Experience:&nbsp;</span>
                            {positionStats==null || positionStats.ivexperience == null
                                ? "No data available at the moment."
                                : getIVExperienceDescription(positionStats.ivexperience)}

                        </p>
                    </div>
                    <div className="flex items-center">
                        <FaUsers className="w-6 h-6 mr-2 text-gray-600" />
                        <p className="text-sm text-gray-700">
                            <span className="font-medium">Average Interview Rounds:&nbsp;</span>
                            {positionStats == null || positionStats.ivround == null
    ? "No data available at the moment."
    : `${positionStats.ivround} ${positionStats.ivround == 1 ? "round" : "rounds"}`}


                        </p>
                    </div>
               
        </div>
    );
}

export default StaticsTableRight;
