import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { ContextProvider } from "./contexts/ContextProvider";

 
const originalConsoleError = console.error;
const originalConsoleWarn = console.warn;
const originalConsoleLog = console.log;

 
console.error = (message, ...args) => {
  if (typeof message === 'string' && message.startsWith('Warning:')) {
    return;
  }
  originalConsoleError(message, ...args);
};


console.warn = (message, ...args) => {

  return;
};


console.log = (message, ...args) => {

  return;
};

ReactDOM.render(
  <ContextProvider>
    <App />
  </ContextProvider>,
  document.getElementById("root")
);
