import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useStateContext } from '../contexts/ContextProvider';
const Notify = ({ item }) => {
    const {server,setTempPost}=useStateContext();
  const text = item.original == null ? "data not available" : item.original;
  const truncateText = (richText, maxLines, decoration = '...') => {
    const imgTagRegex = /<img[^>]+>/gi; 
  
    let lines = [];
    let match;
    let remainingText = richText;
  
    while ((match = imgTagRegex.exec(richText)) !== null && lines.length < maxLines) {
      const imgTag = match[0];
      const imgTagIndex = match.index;
      
      const textBeforeImage = remainingText.substring(0, imgTagIndex);
      lines = textBeforeImage.split('\n');
      
      remainingText = remainingText.substring(imgTagIndex + imgTag.length);
    }
  
   
    lines = lines.concat(remainingText.split('\n').slice(0, maxLines - lines.length));
  
    if (lines.length < richText.split('\n').length) {
      return lines.join('\n') + ` ${decoration}`;
    } else {
      return lines.join('\n');
    }
  };

  const formatDate = (timestamp) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(timestamp).toLocaleDateString(undefined, options);
  };

  const fetchPost = () => {

    const formData = {
      uuid: item.postid,
    }
    fetch(server + '/findPostWithImageDTOByUuid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        
        setTempPost(data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <div onClick={fetchPost} className="border-b bg-white p-4 flex space-x-4 items-start  hover:cursor-pointer hover:bg-stone-50">
      <div className="flex-shrink-0">
        <Avatar  alt={item.sendername} src={item.avatar} />
      </div>
      <div className="flex-1 text-sm">
        <p className="text-gray-600">
          <span className="font-semibold text-black">
            {item.sendername}  
          </span>
          {" "}
          {item.ispost ? 'commented' : 'replied'}
          {" "}
        </p>
        <p className="text-gray-800 my-2">
          {item.reply}
        </p>
        <p className="text-gray-400  text-xs">
          {formatDate(item.time)}
        </p>
        <div className="bg-gray-100 rounded-lg p-2 mt-2">
          <p className="text-gray-600">
             {item.ispost ? 'on your post' : 'to your comment'}:
          </p>
          <div
            className="text-gray-800"
            dangerouslySetInnerHTML={{ __html: truncateText(text, 2) }}
          />
        </div>
        
      </div>
      <div className="flex-shrink-0">
       
      </div>
    </div>
  );
}

export default Notify;
