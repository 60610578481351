import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { Post, Notify, NotifyLikes } from '../components';

const Notifications = (props) => {
  const { cNum, lNum, setTempPost, notificationslikes, setNotificationslikes, tempPost, notificationsreply, setNotificationsreply, posts, setPosts, userId, server } = useStateContext();
  const [cr, setCr] = useState(true);
  const [emo, setEmo] = useState(false);
  const [sys, setSys] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (props.sid === 10) {
      setCr(true);
      setEmo(false);
      setSys(false);
      timeout = setTimeout(() => {
        setNotificationsreply(
          notificationsreply.map(reply => ({
            ...reply,
            haveread: true
          }))
        );
        markNotificationsReplyAsReadByAccount();
      }, 3000);
    }
    setTempPost(null);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [props.sid]);

  const crHandler = () => {
    setCr(true);
    setEmo(false);
    setSys(false);
    setTempPost(null);
  }

  const emoHandler = () => {
    setCr(false);
    setEmo(true);
    setSys(false);
    setTempPost(null);
    const timeout = setTimeout(() => {
      setNotificationslikes(
        notificationslikes.map(reply => ({
          ...reply,
          notificationslikes: {
            ...reply.notificationslikes,
            haveread: true
          }
        }))
      );
      markNotificationsAsReadByAccount();

    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }
  const sysHandler = () => {
    setCr(false);
    setEmo(false);
    setSys(true);
    setTempPost(null);
  }
  const markNotificationsReplyAsReadByAccount = () => {
    let formData = {
      account: userId,
    };
    fetch(server + "/markNotificationsReplyAsReadByAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {

        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const markNotificationsAsReadByAccount = () => {
    let formData = {
      account: userId,
    };
    fetch(server + "/markNotificationsAsReadByAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {

        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div className="m-0 p-0 bg-white border-l "
      style={{ display: (props.sid == 10) ? "block" : "none" }}>
      <div className="m-0  flex gap-0 ">

        <div className="h-screen relative border-r w-full md:w-3/5 max-w-600px mr-4 overflow-y-auto">
          <div class="sticky top-0 z-30 bg-white bg-opacity-70 backdrop-blur-lg">
            <div class="flex items-center justify-between px-4 py-2">
              <button onClick={crHandler} class="relative transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-200 text-gray-700 p-2 rounded-full">
                Comments & Replies
                {cNum > 0 && (
                  <span class="absolute top-0 right-0 bg-black text-white text-xs px-1 rounded-full">{cNum}</span>
                )}
              </button>
              <button onClick={emoHandler} class="transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-200 text-gray-700 p-2 rounded-full">
                Emojis & Likes
                {lNum > 0 && (
                  <span class="absolute top-0 right-0 bg-black text-white text-xs px-1 rounded-full">{lNum}</span>
                )}
              </button>
              <button onClick={sysHandler} class="transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-200 text-gray-700 p-2 rounded-full">
                System Notifications
              </button>
            </div>
          </div>

          {cr && notificationsreply.length === 0 ? (
            <div class="bg-black text-white p-4 rounded-lg shadow-lg text-center">
            <h1 class="text-2xl font-semibold mb-2">No New Comments & Replies 😊</h1>
            <p class="text-gray-300">It's your turn to post! 🚀 🚀 🚀 </p>
          </div>
          

          ) : cr && notificationsreply.map((item) => (
            <Notify item={item} key={item.id} />
          ))}

          {emo && notificationslikes.length === 0 ? (
           <div class="bg-black text-white p-4 rounded-lg shadow-lg text-center">
           <h1 class="text-2xl font-semibold mb-2">No New Emojis & Likes Now ❤️</h1>
           <p class="text-gray-300">But don't worry, keep spreading love and positivity! 🤗🌟</p>
         </div>
         
          ) : emo && notificationslikes.map((item) => (
            <NotifyLikes key={item.notificationslikes.id} item={item} />
          ))}

          {sys && (
           <div class="bg-black text-green-400 p-4 rounded-lg shadow-lg text-center">

           <p class="text-2xl font-mono mb-2">No System Notifications Now 🚧</p>
           {/* <p class="text-gray-300">Our code monkeys are taking a banana break, but they'll swing back with updates soon!</p> */}
           <p class="text-gray-300 mt-2">Found a bug? Please report it to <a href="mailto:didfindDev@gmail.com" class="underline">didfindDev@gmail.com</a>🔧</p>
         </div>
         

          )}
        </div>




        <div className="w-2/5 max-w-315px mr-4 flex  flex-col  h-screen overflow-auto">
          <div className="w-full ">
            <div className=" ">
              {tempPost == null ? (<div></div>) :
                <Post
                  key={tempPost.post.uuid}
                  uuid={tempPost.post.uuid}
                  account={tempPost.post.account}
                  company={tempPost.post.company}
                  tag0={tempPost.post.tag0}
                  tag1={tempPost.post.tag1}
                  tag2={tempPost.post.tag2}
                  title={tempPost.post.title}
                  content={tempPost.post.content}
                  anonymous={tempPost.post.anonymous}
                  time={tempPost.post.time}
                  emoji0={tempPost.post.emoji0}
                  emoji1={tempPost.post.emoji1}
                  emoji2={tempPost.post.emoji2}
                  emoji3={tempPost.post.emoji3}
                  emoji4={tempPost.post.emoji4}
                  flag0={tempPost.post.flag0}
                  flag1={tempPost.post.flag1}
                  flag2={tempPost.post.flag2}
                  flag3={tempPost.post.flag3}
                  comments={tempPost.post.comments}
                  avatar={tempPost.avatar}
                  username={tempPost.username}
                  bookmark={tempPost.post.bookmark}
                likes={tempPost.post.likes}
                rating={tempPost.post.rating}
                />
              }
            </div>
          </div>
        </div>


      </div>



    </div>
  );
};

export default Notifications;
