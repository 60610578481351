import React from 'react'
import StatisticsTagItem from './StatisticsTagItem'
import { useStateContext } from '../contexts/ContextProvider'
const StatisticsTag = (props) => {
    
    return (
        <div className="basis-1/2 flex-1 p-4 rounded-xl bg-white overflow-y-auto">
              
        </div>
    )
}

export default StatisticsTag