import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { GiOctopus } from "react-icons/gi";
import { FiSettings } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../contexts/ContextProvider";
import { links } from "../data/dummy";

const SideBar = (props) => {
  const { notifyNum, bg, activeMenu, setActiveMenu, screenSize } = useStateContext();

  const handleCloseSideBar = (index) => {
    props.changeSidHandler(index);
    // if (screenSize <= 900) {
    //   setActiveMenu(false);
    // }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-stone-950 text-md m-2 hover:bg-light-gray";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray";

  return (
    <div
      className=" ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10"
      // style={{ backgroundColor: bg }}
    >
      {activeMenu && (
        <div>
          <div className="flex flex-col justify-center items-center">
            <div className="flex items-center gap-3 ml-3 mt-4 text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <GiOctopus className="text-2xl " />
              <span className="text-2xl font-semibold">404didfind</span>
            </div>
          </div>




          <div className="mt-10 flex flex-col justify-center items-center">
            {links.map((item) => (
              <div key={item.title}>
                {item.links.map((link, index) => (
                  <div className="mb-4">
                    <NavLink
                      to={`/${link.name}`}
                      onClick={() => handleCloseSideBar(link.count)}
                      className={`inline-flex items-center justify-center hover:bg-stone-100 p-2 rounded-full cursor-pointer focus:outline-none text-left transition duration-300 ease-in-out transform hover:scale-105`}
                      activeClassName="active-style"
         
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? 'black' : '',
                        color: isActive ? 'white' : 'black',
                      })}
                    >
                      <div className="flex justify-center items-center ">
                        <h3 className="text-xl font-semibold">
                          {link.name}

                        </h3>
                        {link.count === 10 ? (
                          <div>
                            {notifyNum > 0 ? (
                              <>
                                &nbsp;
                                <span className="text-xs text-white bg-black px-2 rounded-full">{notifyNum}</span>
                              </>
                            ) : null}
                          </div>
                        ) : null}

                      </div>

                    </NavLink>
                  </div>
                ))}
              </div>
            ))}
          </div>


        </div>
      )}
    </div>
  );
};

export default SideBar;
