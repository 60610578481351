import React, { Fragment, useState } from 'react'
import Button from '@mui/material/Button';
import { useStateContext } from '../contexts/ContextProvider';
const PostComment = (props) => {
  const [commentText, setCommentText] = useState('');
  const { avatarUrl, userId, server, userName } = useStateContext();

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const handlePostComment = () => {
    setCommentText('');
  };
  const addComment = () => {

    if (commentText.trim() !== "") {
      let formData = {
        account: userId,
        post: props.uuid,
        parent: -1,
        content: commentText,
        rate: 0,
      };
      fetch(server + "/newPostReply", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data != null) {
            setCommentText("");
            data.sender=userName;
            const newReply = {
              parentReply: data,
              childReplies: null
            };
            props.addComment(newReply);
            props.comentAddHandler();
          } else {

          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  return (
    <Fragment>
      <div className="mt-3 border  rounded-lg">
        <textarea
          placeholder="Write a comment..."
          className="w-full p-2 bg-white rounded-md border border-gray-300 focus:outline-none"
          value={commentText}
          onChange={handleCommentChange}
        ></textarea>

        <Button variant="text" onClick={addComment}>Post Comment</Button>
      </div>
    </Fragment>
  )
}

export default PostComment