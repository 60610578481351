import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';

const PieStats = (props) => {
  const initializeChart = () => {
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom);
    const option = {
      title: {
        text: ' ',
        subtext: ' ',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'left'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '60%'],
          center: ['50%', '60%'],
          data: props.voteData,
          label: {
            show: false
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  };
  useEffect(() => {
    initializeChart();
  }, [props.divSize.width,props.voteData]);

  return <div id="main" style={{ width: '100%', height: '100%' }}></div>;
};

export default PieStats;  
