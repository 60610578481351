import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useStateContext } from '../contexts/ContextProvider';

const Account = (props) => {
  const { userId, server,avatarUrl,setAvatarUrl,email,setemail,userName, setUserName,setLoginState } = useStateContext();
  const [image, setImage] = useState(null);
  const [editing, setEditing] = useState(false);
  const [name,setname]=useState(userName)
  const [em,setem]=useState(email);

  const handleEditClick = () => {
    setEditing(true);
  };
  const updateAccountInfo = () => {
    let formData = {
      account: userId,
      email: email,
      username: userName,
    };
    fetch(server + "/updateAccountInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {

        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleSaveClick = () => {
    if (isValidEmail(email) && userName.trim() !== "") {
      setEditing(false);
      updateAccountInfo();
    } else {
     
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    handleSubmit(e.target.files[0]);
  };
  const handleSubmit = async (image) => {
    const formData = new FormData();
    formData.append('account', userId);
    formData.append('image', image);
    try {
      const response = await fetch(server + '/updateOrInsertAccountImage', {
        method: 'POST',
        body: formData,
      });
  
      const responseBody = await response.text();  
  
      if (response.ok) {
        setAvatarUrl(responseBody);  
      } else {
        // alert('Error uploading image: ' + responseBody);
      }
    } catch (error) {
      // alert('Error uploading image: ' + error);
    }
  };
  
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };
  const logoutHandler=()=>{
    localStorage.removeItem("isLoggedIn");
localStorage.removeItem("userId");
    setLoginState(false);
  }

  return (
    <div className="m-0 p-0 bg-white border-l "
      style={{ display: (props.sid == 7) ? "block" : "none" }}>
      <div className="m-0  flex gap-0 ">

        <div className="h-screen relative border-r w-full md:w-3/5 max-w-600px mr-4 overflow-y-auto">
          <div className="w-full flex flex-col items-center mb-8">
            <div className="w-full  h-14 bg-black   flex items-center justify-center text-white text-lg font-semibold">USER SETTINGS</div>
            <div className="w-full  bg-gray-100 flex flex-col items-center p-6  ">


              <div className="container mx-auto text-center">
                <div className="bg-white p-1 rounded-full mb-4 inline-block relative group">
                  <Avatar
                    alt="Remy Sharp"
                    src={avatarUrl}
                    sx={{ width: 56, height: 56 }}
                  />
                  <input
                    type="file"
                    accept=".jpg"
                    onChange={handleImageChange}
                    className="mb-4 opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                  />
                  <div className="edit-text absolute bottom-0 left-1/2 transform -translate-x-1/2 text-white py-1 px-2 rounded opacity-0 group-hover:opacity-100 pointer-events-none">
                    Edit
                  </div>
                </div>

              </div>

              <div className="text-center">
                <div className="text-xl font-semibold mb-2">DISPLAY NAME</div>
                {editing ? (
                  <input
                    type="text"
                    className="text-gray-800 font-medium mb-2 px-2 py-1 rounded focus:outline-none"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                ) : (
                  <div className="text-gray-800 font-medium mb-2 px-2 py-1 rounded cursor-pointer hover:underline" onClick={handleEditClick}>
                    {userName}
                  </div>
                )}
                <div className="text-xl font-semibold mt-4">EMAIL</div>
                {editing ? (
                  <input
                    type="email"
                    className="text-gray-800 font-medium mb-2 px-2 py-1 rounded focus:outline-none"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                ) : (
                  <div className="text-gray-800 font-medium mb-2 px-2 py-1 rounded cursor-pointer hover:underline" onClick={handleEditClick}>
                    {email}
                  </div>
                )}

              </div>
              {editing && (
                 
                <button className=" text-black px-4 my-3 rounded-full hover:bg-black hover:text-white transition duration-300 ease-in-out" onClick={handleSaveClick}>
                Save
              </button>
              )}
            </div>
          </div>



          <div className="flex justify-center mt-4">
            <button className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300 ease-in-out" onClick={logoutHandler}>
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
