import React, { useEffect, useState } from "react";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";
import { kanbanData, kanbanGrid } from "../data/dummy";
import { Header } from "../components";
import Box from '@mui/material/Box';

import { FilledInput } from '@mui/material';
import { Typography, Grid, TextField, Button, InputAdornment } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, NativeSelect } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const Profile = (props) => {
  return (
    <div className="border bg-white m-0  p-2 md:p-10 "
      style={{ display: props.sid == 5 ? "block" : "none" }}>
      <p className="text-3xl font-extrabold tracking-tight text-slate-900">
        Basic Information
      </p>
      <br />
      {/* <Header category="" title="Basic Information" /> */}
      <form>
        <div className="flex justify-between">
          <div className="mb-4">
            {/* <label className="block mb-2">First Name: <a className="text-red-500">*</a></label>
            <input
              className="w-full border-2 border-gray-300 rounded-md p-2"
              type="text"
              required
            /> */}
            <TextField
              required
              label="First Name:"
              defaultValue=""
              variant="standard"
            />
          </div>

          <div className="mb-4">
            {/* <label className="block mb-2">Middle Name:</label>
            <input
              className="w-full border-2 border-gray-300 rounded-md p-2"
              type="text"
            /> */}
            <TextField
              label="Middle Name:"
              defaultValue=""
              variant="standard"
            />
          </div>

          <div className="mb-4">
            {/* <label className="block mb-2">Last Name: <a className="text-red-500">*</a></label>
            <input
              className="w-full border-2 border-gray-300 rounded-md p-2"
              type="text"
              required
            /> */}
            <TextField
              required
              label="Last Name:"
              defaultValue=""
              variant="standard"
            />
          </div>

        </div>

        <div className="mb-4">
          {/* <label className="block mb-2">Email: <a className="text-red-500">*</a></label>
          <input
            className="w-full border-2 border-gray-300 rounded-md p-2"
            type="email"
            required
          /> */}
          <TextField
            required
            label="Email:"
            defaultValue=""
            variant="standard"
          />
        </div>

        <div className="mb-4">
          {/* <label className="block mb-2">Phone: <a className="text-red-500">*</a></label>
          <input
            className="w-full border-2 border-gray-300 rounded-md p-2"
            type="tel"
            required
          /> */}
          <TextField
            required
            label="Phone:"
            defaultValue=""
            variant="standard"
          />
        </div>

        {/* <div className="mb-4">
          <label className="block mb-2">
            Have you previously been employed by Companies or organizations? <a className="text-red-500">*</a>
          </label>
          <select
            className="w-full border-2 border-gray-300 rounded-md p-2"
            required
          >
            <option value="">Select an option</option>
            <option value="option1">Yes</option>
            <option value="option2">No</option>
          </select>
        </div> */}
        <div className="mb-4">
          <p className="text-gray-500 text-sm mt-2 mb-2">Upload your Resume*</p>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
          >
            Resume
            <input type="file" hidden />
          </Button>
          
          {/* <Button variant="outlined" size="medium"startIcon={<CloudUploadIcon />}>
          Resume
            <input type="file" hidden />
          </Button> */}

        </div>
        <div className="mb-4">
          <p className="text-gray-500 text-sm mt-2 mb-2">Upload your Cover Letter</p>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
          >
            Cover Letter
            <input type="file" hidden />
          </Button>
           

        </div>
        <div className="mb-4">
          <TextField
            label="LinkedIn:"
            defaultValue=""
            variant="standard"
            style={{
              width: "100%"
            }}
          />
        </div>

        <br />
        <hr />
        <br />
        <p className="text-3xl font-extrabold tracking-tight text-slate-900">
          Work Experience
        </p>
        <br />

        <div>
          <div className="mb-4">
            <TextField
              label="Company:"
              defaultValue=""
              variant="standard"
              style={{
                width: "100%"
              }}
            />

          </div>
          <div className="mb-4">
            <TextField
              label="Job Title:"
              defaultValue=""
              variant="standard"
              style={{
                width: "100%"
              }}
            />
          </div>
          <div className="mb-4">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="City"
                  defaultValue=""
                  variant="standard"
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="State/Province"
                  defaultValue=""
                  variant="standard"
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Country"
                  defaultValue=""
                  variant="standard"
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <div className="mb-4 flex gap-5">
            <div>
              <Typography variant="body1" className="mb-2 text-gray-500">
                From:
              </Typography>
              <input
                className=" border-2 border-gray-300 rounded-md p-2"
                type="date"
              //   required
              />
            </div>

            <div>
              <Typography variant="body1" className="mb-2 text-gray-500">
                To:
              </Typography>
              <input
                className=" border-2 border-gray-300 rounded-md p-2"
                type="date"
              //   required
              />
            </div>
          </div>

          <div className="mb-4">
            <Typography variant="body1" className="mb-2 text-gray-500">
              Role Description:
            </Typography>
            <textarea
              className="w-full h-40 border-2 border-gray-300 rounded-md p-2"
            // placeholder="..."
            ></textarea>
          </div>
          <Button variant="outlined" size="medium">
            Add another
          </Button>
        </div>

        <br />
        <hr />
        <br />
        <p className="text-3xl font-extrabold tracking-tight text-slate-900">
          Education
        </p>
        <br />

        <div>
          <div className="mb-4">
            <TextField
              required
              label="School or University:"
              defaultValue=""
              variant="standard"
              style={{
                width: "100%",
              }}
            />
          </div>
          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Degree:
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">Degree in Progress (no degree awarded)</option>
                <option value="option2">High School Diploma/GED</option>
                <option value="option3">Attendance (No Degree Awarded)</option>
                <option value="option4">Non-Degree Program</option>
                <option value="option5">Associate's Degree</option>
                <option value="option6">Technical Diploma</option>
                <option value="option7">Bachelor's Degree</option>
                <option value="option8">Master's Degree</option>
                <option value="option9">M.B.A.</option>
                <option value="option10">Doctorate Degree</option>
                <option value="option11">M.D.</option>
                <option value="option12">J.D.</option>
              </NativeSelect>
            </FormControl>
          </div>
          <div className="mb-4">
            <TextField
              required
              label="Field of Study:"
              defaultValue=""
              variant="standard"
              style={{
                width: "100%",
              }}
            />
          </div>
          <div className="mb-4 flex gap-5">
            <div>
              <Typography variant="body1" className="mb-2 text-gray-500">
                From:
              </Typography>
              <input
                className=" border-2 border-gray-300 rounded-md p-2"
                type="date"
              //   required
              />
            </div>

            <div>
              <Typography variant="body1" className="mb-2 text-gray-500">
                To:
              </Typography>
              <input
                className=" border-2 border-gray-300 rounded-md p-2"
                type="date"
              //   required
              />
            </div>
          </div>
          <Button variant="outlined" size="medium">
            Add another
          </Button>
        </div>

        <br />
        <hr />
        <br />
        <p className="text-3xl font-extrabold tracking-tight text-slate-900">
          Voluntary Disclosures
        </p>
        <br />

        <div>
          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Are you a U.S. Citizen?
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">Yes</option>
                <option value="option2">No</option>
              </NativeSelect>
            </FormControl>
          </div>
          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Do you require sponsorship to work in the future?
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">Yes</option>
                <option value="option2">No</option>
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Are you a current or former United States Federal Government
                Employee (either civil service, military, or reservist)?
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">Yes</option>
                <option value="option2">No</option>
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                What is the highest level of US Government security clearance do
                you currently hold?
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">None</option>
                <option value="option2">Public Trust</option>
                <option value="option2">Secret</option>
                <option value="option2">Top Secret</option>
                <option value="option2">Top Secret/Sci</option>
              </NativeSelect>
            </FormControl>
          </div>

        </div>

        <div>
          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Race
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">
                  American Indian or Alaska Native

                </option>
                <option value="option2">
                  Asian
                </option>
                <option value="option3">
                  Black or African American
                </option>
                <option value="option4">
                  Native Hawaiian or Other Pacific Islander
                </option>
                <option value="option5">
                  Two or More Races
                </option>
                <option value="option6">
                  White
                </option>
                <option value="option7">
                  I do not wish to provide this information
                </option>
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Hispanic or Latino Ethnicity
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">Yes</option>
                <option value="option2">No</option>
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Veteran Status
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">
                  I am an Active Duty Wartime/Campaign Badge, Armed Forces Service
                  Medal, Recently Separated Veteran or a Disabled Veteran required
                </option>
                <option value="option2">
                  I am a Veteran, but do not fall into any of the Section 4212
                  categories
                </option>
                <option value="option3">
                  I am not a veteran, protected or otherwise
                </option>
                <option value="option4">
                  I do not wish to provide this information
                </option>
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Gender
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">Female</option>
                <option value="option2">Male</option>
                <option value="option3">
                  I do not wish to provide this information/Not Listed
                </option>
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Do you have a disability?
              </InputLabel>
              <NativeSelect defaultValue="">
                <option value=""> </option>
                <option value="option1">
                  Yes, I Have A Disability, Or Have A History/Record Of Having A
                  Disability
                </option>
                <option value="option2">
                  No, I Don't Have A Disability, Or A History/Record Of Having A
                  Disability
                </option>
                <option value="option3">I Don’t Wish To Answer</option>
              </NativeSelect>
            </FormControl>
          </div>
        </div>

              <hr/>
              <br/>
        <Button variant="outlined" size="medium">
         Save All
        </Button>
      </form>
    </div>
  );
};

export default Profile;
