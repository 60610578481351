// import React, { Fragment, useState, useEffect, useRef, forwardRef } from "react";
// import { IoIosAddCircle } from "react-icons/io";
// import Message from "./Message";
// import { format, parse } from "date-fns";
// import { useStateContext } from "../contexts/ContextProvider";
// import { List } from 'react-virtualized';
// import IconButton from '@mui/material/IconButton';
// const ChatWindow = forwardRef((props, ref) => {
//   const [fisrtTimeIn, setFisrtTimeIn] = useState(-1);//-1 initial 0 fetch 1 default websocket 
//   const { loginState,userName, server,websConect,setWebsConect } = useStateContext();
//   const teamId = props.teamsid;
//   const userId = props.userid;
//   const [active, setactive] = useState(props.active);
//   const [message, setMessage] = useState({ messageRank: "", replyMessageId: "", text: "", senderId: userId, isimage: false, time: new Date(), sendername: userName, teamsId: props.teamsid });
//   const [messages, setMessages] = useState([]);

//   const messagesEndRef = useRef(null);
//   const socketRef = useRef(null);
//   const fileInputRef = useRef(null);
//   const changeState = () => {
//     setactive((pre) => !pre);
//   };
//   const changeFisrtTimeIn = () => {
//     setFisrtTimeIn((prev) => prev + 1);
//   };
//   const handleImageSelect = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const imageBase64 = reader.result;
//         sendMessageWithImage(imageBase64);
//       };
//       reader.readAsDataURL(file);
//     }
//     event.target.value = null;
//   };
//   const sendMessageWithImage = (imageBase64) => {
//     const newMessage = {
//       ...message,
//       sendername: userName,
//       text: imageBase64,
//       senderId: userId,
//       isimage: true, // Add a flag to indicate this message contains an image
//     };
//     // const messageJson = JSON.stringify(newMessage);
//     // socketRef.current.send(messageJson);
//     // setMessages([...messages, newMessage]);
     
//     fetch(server + '/newImageMessage', {
//       method: 'POST',
//       headers: {
//           'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(newMessage),
//   })
//       .then(response => {
//           if (response.ok) {
     
//               return response.json();
//           } else {
          
//               console.error('Failed to send message');
//               throw new Error('Failed to send message');  
//           }
//       })
//       .then(data => {
//           if (data != null) {
//             const messageJson = JSON.stringify(data);
//     socketRef.current.send(messageJson);
//     setMessages([...messages, newMessage]);
//           } else {
               
//               console.error('Received null data from server');
//           }
//       })
//       .catch(error => {
     
//           console.error('Error:', error);
//       });
  
  
//   };


//   React.useImperativeHandle(ref, () => ({
//     changeState: changeState,
//     changeFisrtTimeIn: changeFisrtTimeIn
//   }));
//   useEffect(() => {
//     if (messagesEndRef.current) {
//       messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [messages]);
//   useEffect(() => {
//     if (fisrtTimeIn === 0) {
//       fetchMessages();
//     }
//   }, [fisrtTimeIn]);
//   const fetchMessages = () => {
//     const formData = {
//       teamsId: teamId,
//     }
//     fetch(server + '/getAllMessage', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(formData)
//     }).then(response => response.json())
//       .then(data => {
//         const teamsList = Object.values(data);
//         setMessages(teamsList);
//         websocketConect();
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }
//   const fetchMore = () => {
//     const formData = {
//       teamsId: teamId,
//       messageid:messages[0].id,
//     }
//     fetch(server + '/getMoreMessage', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(formData)
//     }).then(response => response.json())
//       .then(data => {
//         const teamsList = Object.values(data);
//         setMessages((prev) => {
//           prev.unshift(...teamsList);  
//           return prev;  
//         });
//         setIsLoading(false);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }
//   const websocketConect=()=>{
//     // const serverUrl = new URL(server);
//     // const host = serverUrl.hostname;
//     // const port = serverUrl.port;

// const socketUrl = `wss://404didfind.com/api/websocket/${userId}/${teamId}`;
//     // const socketUrl = `wss://localhost:8080/api/websocket/${userId}/${teamId}`;
   
//     socketRef.current = new WebSocket(socketUrl);
//     socketRef.current.onopen = () => {
//       setWebsConect(true);
//     };

//     socketRef.current.onmessage = (event) => {
//       const receivedMessage = event.data;
//       const parsedMessage = JSON.parse(receivedMessage);
//       console.log(receivedMessage)
//       setMessages((prevMessages) => [...prevMessages, parsedMessage]);
//     };
//     socketRef.current.onclose = () => {
      
//       setWebsConect(false); 
//       setTimeout(() => {
//         if (loginState) {
//           websocketConect();
//         }
//       }, 5000);
//     };
//   }

  

//   const handleSend = () => {
//     if (message.text.trim() !== "") {
//       const messageJson = JSON.stringify(message);
//       socketRef.current.send(messageJson);
      
//       setMessages([...messages, message]);
//       setMessage({
//         ...message,
//         text: "",
//       });
//     }
//   };

//   const handleInputChange = (event) => {
//     const currentTime = new Date();
//     setMessage({
//       ...message,
//       sendername: userName,
//       text: event.target.value,
//       senderId: userId,
//       time: currentTime,
//     });
//   };
//   const handleKeyDown = (event) => {
//     if (event.key === "Enter") {
//       handleSend();
//     }
//   };
//   const [isLoading, setIsLoading] = useState(false);
//   const handleScroll = () => {
//     if (messagesEndRef2.current.scrollTop === 0 && !isLoading) {
//       setIsLoading(true);
     
//       fetchMore();
//     }
//   };
//   const messagesEndRef2 = useRef(null);
//   return (
//       <div className={`flex flex-col ${active ? '' : 'hidden'}`} style={{ height: "100%" }}>
//         <div className="flex items-center p-2 bg-gray-800 text-white text-lg font-semibold">
//           <div style={{ fontSize: `clamp(14px, 1.5vw, 20px)` }}>
//             {props.details}
//           </div>
//         </div>
        

//         <div className="flex-grow overflow-auto max-h-full m-2" onScroll={handleScroll} ref={messagesEndRef2} 
//        >
//         {isLoading && (
//         <div className="loading-indicator">
//           <p>Loading...</p>
//         </div>
//       )}
//           {messages.map((msg, index) => (
//             <Message key={msg.id} sendername={msg.sendername} text={msg.text} iff={msg.senderId != userId} isImage={msg.isimage} time={msg.time} />
//           ))}
//           <div ref={messagesEndRef} />
//         </div>
//         <div className="bottom-0">
//           <div className="m-2 mb-3 mt-0 rounded-xl p-0 flex justify-center items-center hover:drop-shadow-lg" style={{ backgroundColor: "rgb(244,244,246)" }}>
//             <input
//               type="file"
//               accept="image/*"
//               onChange={handleImageSelect}
//               style={{ display: "none" }}
//               ref={fileInputRef}
//             />

//             <button onClick={() => fileInputRef.current.click()} type="button" style={{ color: "rgb(69,71,140)", borderRadius: "50%" }} className="text-4xl p-2 hover:drop-shadow-xl hover:bg-light-gray">
//               <IoIosAddCircle />
//             </button>

//              <input
//               onChange={handleInputChange}
//               onKeyDown={handleKeyDown}
//               value={message.text}
//               className="p-3  w-full bg-transparent focus:outline-none"
//               type="text"
//               placeholder="Message..."
//             />

//           </div>
//         </div> 

//       </div>
 
//   );
// });

// export default React.memo(ChatWindow);
import React, { Fragment, useState, useEffect, useRef, forwardRef } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Message from "./Message";
import { format, parse } from "date-fns";
import { useStateContext } from "../contexts/ContextProvider";
import { List } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
const ChatWindow = forwardRef((props, ref) => {
  const [fisrtTimeIn, setFisrtTimeIn] = useState(-1);//-1 initial 0 fetch 1 default websocket 
  const { loginState,userName, server,websConect,setWebsConect } = useStateContext();
  const teamId = props.teamsid;
  const userId = props.userid;
  const [active, setactive] = useState(props.active);
  const [message, setMessage] = useState({ messageRank: "", replyMessageId: "", text: "", senderId: userId, isimage: false, time: new Date(), sendername: userName, teamsId: props.teamsid });
  const [messages, setMessages] = useState([]);
  const [listenMessages,setListenMessage]=useState(false);
  const [lastMessageId,setlastMessageId]=useState(-1);
  const messagesEndRef = useRef(null);
  const socketRef = useRef(null);
  const fileInputRef = useRef(null);
  const changeState = () => {
    setactive((pre) => !pre);
  };
  const changeFisrtTimeIn = () => {
    setFisrtTimeIn((prev) => prev + 1);
  };
  useEffect(() => {
    let intervalId;
    if (active&&messages.length > 0&&loginState==true) {
      intervalId = setInterval(() => {
        fetchNewData(messages.length);
        // console.log("----"+messages.length)
      }, 1000); 
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [active,messages]);
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageBase64 = reader.result;
        sendMessageWithImage(imageBase64);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };
  const sendMessageWithImage = (imageBase64) => {
    const newMessage = {
      ...message,
      sendername: userName,
      text: imageBase64,
      senderId: userId,
      isimage: true, // Add a flag to indicate this message contains an image
    };
    // const messageJson = JSON.stringify(newMessage);
    // socketRef.current.send(messageJson);
    // setMessages([...messages, newMessage]);
     
    fetch(server + '/newImageMessage', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(newMessage),
  })
      .then(response => {
          if (response.ok) {
              return response.json();
          } else {
              console.error('Failed to send message');
              throw new Error('Failed to send message');  
          }
      })
      .then(data => {
          if (data != null) {
    setMessages([...messages, data]);
          } else {
              console.error('Received null data from server');
          }
      })
      .catch(error => {
          console.error('Error:', error);
      });
  };
  useEffect(()=>{
    if (messages.length > 0) {
      setlastMessageId(messages[messages.length - 1]?.id);
    }
  },[messages])
  const sendMessageWithNoImage = (messageJson) => {
    fetch(server + '/newMessage', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: messageJson ,
  })
      .then(response => {
          if (response.ok) {
     
              return response.json();
          } else {
          
              console.error('Failed to send message');
              throw new Error('Failed to send message');  
          }
      })
      .then(data => {
          if (data != null) {
            setMessages([...messages, data]);
            setMessage({
              ...message,
              text: "",
            });
          } else {
              console.error('Received null data from server');
          }
      })
      .catch(error => {
          console.error('Error:', error);
      });
  
  
  };


  React.useImperativeHandle(ref, () => ({
    changeState: changeState,
    changeFisrtTimeIn: changeFisrtTimeIn
  }));
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  useEffect(() => {
    if (fisrtTimeIn === 0) {
      fetchMessages();
    }
  }, [fisrtTimeIn]);
  const fetchMessages = () => {
    const formData = {
      teamsId: teamId,
    }
    fetch(server + '/getAllMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        const teamsList = Object.values(data);
        setMessages(teamsList);
        // websocketConect();
      })
      .catch(error => {
        console.error(error);
      });
  }
  const fetchMore = () => {
    const formData = {
      teamsId: teamId,
      messageid:messages[0].id,
    }
    fetch(server + '/getMoreMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
        const teamsList = Object.values(data);
        setMessages((prev) => {
          prev.unshift(...teamsList);  
          return prev;  
        });
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const fetchNewData = (L) => {
    
    const formData = {
      teamsId: teamId,
      messageid:messages[L - 1].id,
    }
    fetch(server + '/getNewMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => response.json())
      .then(data => {
         
        if(data.length!=0){
          const teamsList = Object.values(data);
        setMessages((prev) => {
          return [...prev, ...teamsList];
        }); 
        }       
      })
      .catch(error => {
        console.error(error);
      });
  }
//   const websocketConect=()=>{

// const socketUrl = `wss://404didfind.com/api/websocket/${userId}/${teamId}`;
//     // const socketUrl = `wss://localhost:8080/api/websocket/${userId}/${teamId}`;
   
//     socketRef.current = new WebSocket(socketUrl);
//     socketRef.current.onopen = () => {
//       setWebsConect(true);
//     };

//     socketRef.current.onmessage = (event) => {
//       const receivedMessage = event.data;
//       const parsedMessage = JSON.parse(receivedMessage);
//       console.log(receivedMessage)
//       setMessages((prevMessages) => [...prevMessages, parsedMessage]);
//     };
//     socketRef.current.onclose = () => {
      
//       setWebsConect(false); 
//       setTimeout(() => {
//         if (loginState) {
//           websocketConect();
//         }
//       }, 5000);
//     };
//   }

  

  const handleSend = () => {
    if (message.text.trim() !== "") {
      const messageJson = JSON.stringify(message);
      // socketRef.current.send(messageJson);
      sendMessageWithNoImage(messageJson);
      // setMessages([...messages, message]);
      // setMessage({
      //   ...message,
      //   text: "",
      // });
    }
  };

  const handleInputChange = (event) => {
    const currentTime = new Date();
    setMessage({
      ...message,
      sendername: userName,
      text: event.target.value,
      senderId: userId,
      time: currentTime,
    });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleScroll = () => {
    if (messagesEndRef2.current.scrollTop === 0 && !isLoading) {
      setIsLoading(true);
     
      fetchMore();
    }
  };
  const messagesEndRef2 = useRef(null);
  return (
      <div className={`flex flex-col ${active ? '' : 'hidden'}`} style={{ height: "100%" }}>
        <div className="flex items-center p-2 bg-gray-800 text-white text-lg font-semibold">
          <div style={{ fontSize: `clamp(14px, 1.5vw, 20px)` }}>
            {props.details}
          </div>
        </div>
        

        <div className="flex-grow overflow-auto max-h-full m-2" onScroll={handleScroll} ref={messagesEndRef2} 
       >
        {isLoading && (
        <div className="loading-indicator">
          <p>Loading...</p>
        </div>
      )}
          {messages.map((msg, index) => (
            <Message key={msg.id} sendername={msg.sendername} text={msg.text} iff={msg.senderId != userId} isImage={msg.isimage} time={msg.time} />
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="bottom-0">
          <div className="m-2 mb-3 mt-0 rounded-xl p-0 flex justify-center items-center hover:drop-shadow-lg" style={{ backgroundColor: "rgb(244,244,246)" }}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageSelect}
              style={{ display: "none" }}
              ref={fileInputRef}
            />

            <button onClick={() => fileInputRef.current.click()} type="button" style={{ color: "rgb(69,71,140)", borderRadius: "50%" }} className="text-4xl p-2 hover:drop-shadow-xl hover:bg-light-gray">
              <IoIosAddCircle />
            </button>

             <input
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={message.text}
              className="p-3  w-full bg-transparent focus:outline-none"
              type="text"
              placeholder="Message..."
            />

          </div>
        </div> 

      </div>
 
  );
});

export default React.memo(ChatWindow);
