import React, { useState, useRef, useEffect, Fragment } from "react";
import { ChatWindow, ServerWindow, Header, StatisticsTag, StatisticsCard, StaticsTable, Reminder, StatisticsTagItem, StatisticsTableCard } from "../components";
import Pie from "./charts/Pie";
import { useStateContext } from "../contexts/ContextProvider";
import { Box, Tabs, Tab } from '@mui/material';
import {StatsTab} from "../components";

const Wall = () => {
    return (
        <div className="mx-1 rounded-full" style={{
            backgroundColor: "rgb(235,235,235)",
            width: "2px",
        }}></div>
    );
}

const Mystat = (props) => {
    const [controlFlag, setControlFlag] = useState(-1);
    const {bg}=useStateContext();
    return (
        <div className="m-0 p-0 "
            style={{ display: props.sid == 4.5 ? "block" : "none" ,
            backgroundColor:bg}}>
            <div className=" rounded-xl flex ">
                <div className="basis-1/3  p-0 m-0">
                    <Reminder>
                        <div className="grid grid-cols-2 gap-4" style={{ height: "60vh" }}>

                        </div>
                    </Reminder>
                </div>

            
                <div className="basis-2/3">
                    <StatisticsCard title={"Today's updates"}>
                        <div className="flex flex-row gap-3"
                            style={{ height: "60vh" }}>
                                <StatsTab/>
                        </div>
                    </StatisticsCard>
                </div>

            </div>



            <StatisticsTableCard controlFlag={controlFlag} setControlFlag={setControlFlag} title={"My Log"}>
                <StaticsTable controlFlag={controlFlag}/>
            </StatisticsTableCard>

        <br/>
        <br/>
        <br/>
        </div>
    );
};
export default React.memo(Mystat);
