import React, { useState } from 'react'
import ReminderListItem from './ReminderListItem'
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useStateContext } from '../contexts/ContextProvider';
const ReminderList = () => {
    const { reminderListOA, reminderListIV } = useStateContext();
    const [oaControl, setOaControl] = useState(false);
    const [viControl, setViControl] = useState(false);
    const oaControlHandler = () => {
        setOaControl((prev) => !prev);
    };
    const viControlHandler = () => {
        setViControl((prev) => !prev);
    };
    return (
        <div className='h-full overflow-y-auto p-0 m-0 overflow-hidden'>

            <div className=" flex justify-between items-center sticky top-0 bg-white hover:bg-gray-100" style={{ zIndex: 100 }}>
                <h3 className=" w-full  text-lg font-semibold mb-1 ">
                    Online Assessment
                    <button type="button" onClick={oaControlHandler} style={{ color: "rgb(69,71,140)", borderRadius: "50%" }} className="text-base p-2 ">
                        {oaControl ? <FaAngleUp /> : <FaAngleDown />}
                    </button>
                </h3>
                {reminderListOA && reminderListOA.length > 0 && (
                    <span className="whitespace-nowrap inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-black text-white">
                        {reminderListOA.length}
                    </span>
                )}

            </div>
            {oaControl ? <div className='overflow-y-auto'>
                {reminderListOA.map((item) => (<ReminderListItem type={"1"} item={item} key={item.uuid} />))}
            </div> : ""}

            <div className="bg-white flex justify-between items-center sticky top-0 bg-white hover:bg-gray-100" style={{ zIndex: 100 }}>
                <h3 className="w-full  text-lg font-semibold mb-1 ">
                    Virtual Interview
                    <button type="button" onClick={viControlHandler} style={{ color: "rgb(69,71,140)", borderRadius: "50%" }} className="text-base p-2 ">
                        {viControl ? <FaAngleUp /> : <FaAngleDown />}
                    </button>
                </h3>
                {reminderListIV && reminderListIV.length > 0 && (
                    <span className="whitespace-nowrap inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-black text-white">
                        {reminderListIV.length}
                    </span>
                )}

            </div>
            {viControl ? <div className='overflow-y-auto'>
                {reminderListIV.map((item) => (<ReminderListItem type={"2"} item={item} key={item.uuid} />))}
            </div> : ""}
        </div>
    )
}

export default ReminderList
