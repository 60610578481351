import React, { useState, useEffect, useRef,forwardRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';

import Pie from './rcharts/Pie';
import PieStats from './rcharts/PieStats';
import AreaStats from './rcharts/AreaStats';
import TrendStats from './rcharts/TrendStats';
import { useStateContext } from '../contexts/ContextProvider';
const VoteComponent = forwardRef((props, ref) => {
  const {server} = useStateContext()
  const [value, setValue] = useState("");
  const divRef = useRef(null);
  const [refNullCheck,setrefNullCheck] = useState(false);
  const [width,setWidth]=useState(0);
  const [divSize, setDivSize] = useState({ width: 0, height: 0 });
  const [displayChart,setdisplayChart]=useState(false);
  const [active, setactive] = useState(props.active);
  const changeState = () => {
    setactive((pre) => !pre);
  };
  const changeFisrtTimeIn = () => {
    // setFisrtTimeIn((prev)=>prev+1);
  };
  React.useImperativeHandle(ref, () => ({
    changeState: changeState,
    changeFisrtTimeIn:changeFisrtTimeIn
  }));
  
  // const handleResize = () => {
  //   if (divRef.current) {
  //     const { width, height } = divRef.current.getBoundingClientRect();
  //     setDivSize({ width, height });
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   handleResize();
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const initialData = [
    { value: 0, name: 'Virtual Interview' },
    { value: 0, name: 'Offer Stage' },
    { value: 0, name: 'Online Assessment' },
    { value: 0, name: 'Reject' },
    { value: 0, name: 'Just submitted' },
  ];
  const [voteData, setVoteData] = useState(initialData);
  const getVoteStats = () => {
    let formData = {
      teams: props.teamsid,
    };
    fetch(server + "/getVoteStats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
         setVoteData([
          { value: data.iv, name: 'Virtual Interview' },
          { value: data.offer, name: 'Offer Stage' },
          { value: data.oa, name: 'Online Assessment' },
          { value: data.reject, name: 'Reject' },
          { value: data.submitted, name: 'Just submitted' },
        ]);
        } else {
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const nextHandler= async()=>{
    await getVoteStats()
    setdisplayChart(true);
    const { width, height } = divRef.current.getBoundingClientRect();
    setDivSize({ width, height });
    
  };

  return (
    active?(
    <div className='flex flex-col h-full p-0 m-0'>
      <div className='h-1/2 p-0 m-0' ref={divRef} >
        {!displayChart?<FormControl>
          {/* <FormLabel id="demo-controlled-radio-buttons-group">What is your current status?</FormLabel> */}
          <div>{props.name}</div>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            {/* <FormControlLabel value="Submitted" control={<Radio />} label="Submitted" />
            <FormControlLabel value="Received OA" control={<Radio />} label="Received OA" />
            <FormControlLabel value="Scheduled Virtual Interview" control={<Radio />} label="Scheduled Virtual Interview" />
            <FormControlLabel value="Received Offer" control={<Radio />} label="Received Offer" />
            <FormControlLabel value="Rejected" control={<Radio />} label="Rejected" /> */}
          </RadioGroup>
          <Button sx={{ mt: 1, mr: 1 }} onClick={nextHandler} type="button" variant="outlined">
            explore
          </Button>
        </FormControl>:<PieStats voteData={voteData} divSize={divSize} teams={props.teamsid}/>}
      </div>
    </div>
  ):null);
  
})
export default VoteComponent;

