import React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useStateContext } from '../contexts/ContextProvider';
const NotifyLikes = ({ item }) => {
    const dataList = [item.notificationslikes, ...item.notificationslikesList];
    const {server,setTempPost}=useStateContext();
    const truncateText = (richText, maxLines, decoration = '...') => {
        const imgTagRegex = /<img[^>]+>/gi;

        let lines = [];
        let match;
        let remainingText = richText;

        while ((match = imgTagRegex.exec(richText)) !== null && lines.length < maxLines) {
            const imgTag = match[0];
            const imgTagIndex = match.index;

            const textBeforeImage = remainingText.substring(0, imgTagIndex);
            lines = textBeforeImage.split('\n');

            remainingText = remainingText.substring(imgTagIndex + imgTag.length);
        }

        lines = lines.concat(remainingText.split('\n').slice(0, maxLines - lines.length));

        if (lines.length < richText.split('\n').length) {
            return lines.join('\n') + ` ${decoration}`;
        } else {
            return lines.join('\n');
        }
    };

    const likeNotification = dataList[0];
    const isPost = likeNotification.ispost;
    const likeCount = dataList.length;
    const emojiMap = {
        0: { text: 'is impressive!', emoji: '👏' },
        1: { text: 'deserves celebration!', emoji: '🎉' },
        2: { text: 'makes me think.', emoji: '🤔' },
        3: { text: 'is interesting!', emoji: '🤣' },
        4: { text: 'is infuriating!', emoji: '😡' },
    };
    const fetchPost = () => {

        const formData = {
          uuid: dataList[0].postid,
        }
        fetch(server + '/findPostWithImageDTOByUuid', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        }).then(response => response.json())
          .then(data => {
            
            setTempPost(data);
          })
          .catch(error => {
            console.error(error);
          });
      }
    return (
        <div onClick={fetchPost} className="bg-white border-b p-4 flex flex-col items-start hover:cursor-pointer hover:bg-stone-50">
            <div className='flex items-center'>
                <AvatarGroup max={likeCount} className="mb-2 mr-3">
                    {dataList.slice(0, 4).map(like => (
                        <Avatar
                            key={like.id}
                            alt={like.sendername}
                            src={like.avatar}
                            className="ring-2 ring-white"
                        />
                    ))}
                </AvatarGroup>
                <div>
                    <p className="text-sm text-gray-600 mb-1">
                        {likeCount === 1 ? (
                            <span className="font-semibold text-black">{likeNotification.sendername}</span>
                        ) : (
                            <>
                                <span className="font-semibold text-black">{likeNotification.sendername}</span> and{' '}
                                <span className="font-semibold text-black">{likeCount - 1} others</span>
                            </>
                        )}
                        {' '}
                        {isPost ? "found" : "liked"}
                        {' '}
                        your{' '}
                        <span className="font-semibold text-black">{isPost ? 'post' : 'comment'}</span>
                        {' '}
                        {isPost && (
                            <span>
                                {emojiMap[likeNotification.emoji].text}
                                {' '}
                                {emojiMap[likeNotification.emoji].emoji}
                            </span>
                        )}
                    </p>
                </div>
            </div>
            {!isPost ? (
                <div className="mb-1 p-2 bg-yellow-300 rounded-md">
                    <p className="text-xs text-gray-500 ">{likeNotification.original}</p>
                </div>
            ) : (
                <div className="mb-1 p-2 bg-gray-100 rounded-md">
                    <p
                        className="text-xs text-gray-500"
                        dangerouslySetInnerHTML={{ __html: truncateText(likeNotification.original, 2) }}
                    />
                </div>
            )}
        </div>
    );
};

export default NotifyLikes;
