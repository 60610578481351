import React, { useState, useRef, useEffect, Fragment } from "react";
import { ChatWindow, ServerWindow, VoteComponent } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
const Community = (props) => {
  const [messageName, setMessageName] = useState("Welcome");
  const { teamsList } = useStateContext();
  const [tl, setTl] = useState(teamsList);
  // initial value-1
  const [prevTeam, setPrevTeam] = useState(-1);
  const [prevTeam2, setPrevTeam2] = useState(-1);

  const changeServerHandler = (newServer) => {
    
    if (prevTeam != -1&&chatWindowRefs.current[prevTeam]!=null) {
      chatWindowRefs.current[prevTeam].changeState();
    }
    setPrevTeam(newServer);
    // console.log(chatWindowRefs)
    chatWindowRefs.current[newServer].changeState();
    chatWindowRefs.current[newServer].changeFisrtTimeIn();
  }

  const changeVoteHandler = (newServer) => {
    if (prevTeam2 != -1) {
      voteWindowRefs.current[prevTeam2].changeState();
    }
    setPrevTeam2(newServer);
    voteWindowRefs.current[newServer].changeState();
    voteWindowRefs.current[newServer].changeFisrtTimeIn();
  }
  const [prevRef, setPrevRef] = useState(null);
  const chatWindowRefs = useRef({});
  const voteWindowRefs = useRef({});
  // const test1 = useRef();
  const [filterText, setFilterText] = useState("");
  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  // useEffect(()=>{
  //   console.log(teamsList
  //     .filter((team) => team.teamname.toLowerCase().includes(filterText.toLowerCase())))
  // },[filterText])

  const handleTeamClick = (team) => {
    const teamName = team.teamname; 
    // alert(teamName);
  };
  return (
    <div className="m-0 p-0 h-[100vh]" style={{ display: props.sid == 6 ? "block" : "none" }}>
      <div className="m-0 h-full border bg-white flex">
        <div className="flex flex-col flex-grow-0 w-3/12 overflow-auto">
          <div className="bg-black text-white sticky top-0 p-4 pt-2 pb-2 text-xl font-bold mb-2 shadow-2xl"
            >Teams</div>
          <input
            type="text"
            placeholder="Search teams..."
            value={filterText}
            onChange={handleFilterChange}
            className="p-2 mb-2 mx-2 border-b-2 border-gray-300 outline-none bg-transparent text-gray-600 text-sm"
          />


          {teamsList
            .filter((team) => team.teamname.toLowerCase().includes(filterText.toLowerCase()))
            .map((team) => (
              <ServerWindow
                prevRef={prevRef}
                setPrevRef={setPrevRef}
                // onClick={() => {changeServerHandler(team.teamname);changeVoteHandler(team.teams)}}
               
                key={team.teams}
                index={team.teams}
                onNewServer1={changeServerHandler}
                onNewServer2={changeVoteHandler}
                name={team.teamname}
                filterText={filterText}
              />
            ))}
        </div>
        <div className="flex flex-col grow basis-6/12 border relative">
        
        {teamsList
            .filter((team) => team.teamname.toLowerCase().includes(filterText.toLowerCase()))
            .map((team) => (
            <ChatWindow
              key={team.teams}
              teamsId={team.teams}
              teamsid={team.teams}
              details={team.details}
              userid={team.account}
              identify={team.identify}
              active={false}
              name={team.teamname}
              ref={(ref) => chatWindowRefs.current[team.teams] = ref}
            />
          ))}
        </div>
        
        <div className="basis-3/12 p-2 flex flex-col">
          <h2 className="text-lg font-semibold mb-2 text-gray-800 border-gray-300 pb-1">Statistics</h2>
        
          <div className="flex-grow ">
              {teamsList.map((team)=>(
                <VoteComponent
                  key={team.teams}
                  teamsId={team.teams}
                  teamsid={team.teams}
                  userid={team.account}
                  identify={team.identify}
                  active={false}
                  name={team.teamname}
                  ref={(ref) => voteWindowRefs.current[team.teams] = ref}
              />
              ))}
          </div>
        </div>
        
      </div>
    </div>

  );
};
export default React.memo(Community);
