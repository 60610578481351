import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PostForm from './PostForm';
const steps = ['Company', 'Tag', 'Post'];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: "80vh",
    bgcolor: '#f2f2f2',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: 3,
    p: 4,
};
const PostFormSteper = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const postHandler = () => {
        props.clear();
        props.handleClose();
    }
    const handleNext = () => {
        if (activeStep == 0) {
            if (!props.company || /^\s*$/.test(props.company)) {
                return;
            }
        }

        if (activeStep === 2) {
            if (!props.editorContent || /^\s*$/.test(props.editorContent)) {
                return;
            }
            props.newPost(false);
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleNextAnonymous = async () => {
        if (!props.editorContent || /^\s*$/.test(props.editorContent)) {
            return;
        }
        await props.changeAnonymous();
        props.newPost(true);
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };


    const handleCompanyChange = (event) => {
        const newCompany = event.target.value;
        props.setPostCompany(newCompany);
    };
    const handleTag0Change = (event) => {
        const newTag = event.target.value;
        props.setTag0(newTag);
    };
    const handleTag1Change = (event) => {
        const newTag = event.target.value;
        props.setTag1(newTag);
    };
    const handleTag2Change = (event) => {
        const newTag = event.target.value;
        props.setTag2(newTag);
    };
    const handleTitleChange = (value) => {
        props.setTitle(value);
    };
    const handleContentChange = (value) => {
        props.setContent(value);
    };


    return (
        <Box sx={style}>
         {/* <div sx={style}> */}
            <div className='flex flex-col h-full w-full '>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - Already posted
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={postHandler}>Close</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <div className='h-full w-full flex flex-col  '>
                        {activeStep === 0 && (
                            <div>
                                <Typography sx={{ mt: 2, mb: 1 }}>Which company is your post related to?</Typography>
                                <TextField
                                    required
                                    label="company"
                                    variant="standard"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    defaultValue={props.company}
                                    onChange={handleCompanyChange}
                                />
                            </div>
                        )}

                        {activeStep === 1 && (
                            <div className=' h-full'>
                                <Typography sx={{ mt: 2, mb: 1 }}>You can choose to add three tags to your post</Typography>
                                <a className='text-sm'>This step is optional, but accurate tags can enhance the system's ability to recommend your post to other users.</a>
                                <TextField
                                    label=""
                                    variant="standard"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    defaultValue={props.tag0}
                                    onChange={handleTag0Change}
                                />
                                <TextField
                                    label=" "
                                    variant="standard"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    defaultValue={props.tag1}
                                    onChange={handleTag1Change}
                                />
                                <TextField
                                    label=" "
                                    variant="standard"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    defaultValue={props.tag2}
                                    onChange={handleTag2Change}
                                />
                            </div>
                        )}

                        {activeStep === 2 && (
                            <div className='h-full   'style={{ overflow: 'hidden',height: '80%' }}>
                               
                                <PostForm editChangeHandler={props.editChangeHandler} content={props.content} setContent={handleContentChange} title={props.title} setTitle={handleTitleChange} />
                            </div>
                        )}
                        
                        <div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}
                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Post' : 'Next'}
                            </Button>
                            {activeStep === 2 ? <Button color="secondary" onClick={handleNextAnonymous}>
                                Post anonymously
                            </Button> : null}

                        </Box>
                        </div>
                        {/* <div className="bg-blue-200  ">Box 1</div>
                        <div className="bg-green-200  flex-grow "style={{ overflow: 'hidden' }}>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                            <div className='mb-2'>sad</div>
                        </div>
                        <div className="bg-yellow-200 p-4">Box 3</div> */}

                    </div>
                )}
            </div>
        </Box>
    );
}
export default PostFormSteper;