import React, { Fragment, useEffect, useState } from 'react';
import { BsExclamationCircle } from "react-icons/bs";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import StaticsTableTr from './StaticsTableTr';
import { useStateContext } from '../contexts/ContextProvider';
import StaticsTableRight from './StaticsTableRight';
const StatisticsTableCard = (props) => {
  const { mystatsPost, setMyStatsPost, joblog, setJoblog, server } = useStateContext();
  const [js, setjs] = useState(0);
  const [oa, setoa] = useState(0);
  const [vi, setvi] = useState(0);
  const [os, setos] = useState(0);
  const [rj, setrj] = useState(0);
  const [to, setto] = useState(0);
  const [positionStats, setPositionStats] = useState();
  const jsHandler = () => {
    if (props.controlFlag == 0) {
      props.setControlFlag(-1);
    } else {
      props.setControlFlag(0);
    }
  }
  const oaHandler = () => {
    if (props.controlFlag == 1) {
      props.setControlFlag(-1);
    } else {
      props.setControlFlag(1);
    }
  }
  const viHandler = () => {
    if (props.controlFlag == 2) {
      props.setControlFlag(-1);
    } else {
      props.setControlFlag(2);
    }
  }
  const osHandler = () => {
    if (props.controlFlag == 3) {
      props.setControlFlag(-1);
    } else {
      props.setControlFlag(3);
    }
  }
  const rjHandler = () => {
    if (props.controlFlag == 4) {
      props.setControlFlag(-1);
    } else {
      props.setControlFlag(4);
    }
  }
  const ttHandler = () => {
    props.setControlFlag(-1);
  }
  useEffect(() => {
    let o0 = 0;
    let o1 = 0;
    let o2 = 0;
    let o3 = 0;
    let o4 = 0;
    let o5 = 0;

    joblog.forEach((element) => {
      switch (element.status.toString()) {
        case "0":
          o0 += 1;
          break;
        case "1":
          o1 += 1;
          break;
        case "2":
          o2 += 1;
          break;
        case "3":
          o3 += 1;
          break;
        case "4":
          o4 += 1;
          break;
        default:

          break;
      }
      o5 += 1;
    });
    setjs(o0);
    setoa(o1);
    setvi(o2);
    setos(o3);
    setrj(o4)
    setto(o5);
  }, [joblog]);
  useEffect(() => {

    findPositionStatByJobid();
  }, [mystatsPost]);
  const findPositionStatByJobid = () => {
    if(mystatsPost==null){
      return;
    }
    let formData = {
      jobid: mystatsPost,
    };
    fetch(server + "/findPositionStatByJobid", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
       
        if (data) {
          setPositionStats(data);
        } else {
        }
      })
      .catch((error) => {
      });
  }
  return (
    <div className='p-0 m-0' style={{ height: "90vh" }}>
      <div className="m-2 p-2 md:p-3 bg-white rounded-t-xl border border-b-0 flex flex-col mb-0" style={{
        backgroundColor: "rgb(255,251,240)"
      }}>
        <div className='flex items-center mb-3'>
          <p className='text-3xl font-extrabold tracking-tight text-slate-900' style={{
            fontFamily: 'Publico Headline, sans-serif',
            color: 'rgb(74, 74, 74)'
          }}>
            {props.title}

          </p>
        </div>
        <hr style={{ border: 'none', borderTop: '4px solid rgb(74, 74, 74)' }} />
        <div className='mt-1 mb-3 text-sm'>

        </div>
        <hr style={{ border: 'none', borderTop: '2px solid rgb(74, 74, 74)' }} />

      </div>

      <div className="m-2 p-2 md:p-3 bg-white border flex mt-0 border-y-0 mb-0" style={{ backgroundColor: "rgb(255, 251, 240)" }}>
        <div className="w-2/5">
          <table className="w-full border" style={{ borderColor: "rgb(217, 203, 205)" }}>
            <tbody>
              <tr >
                <td onClick={jsHandler}
                  className={`hover:bg-stone-300 bg-stone-200 border p-2 w-4/5 font-bold text-sm ${props.controlFlag == 0 ? 'bg-red-300' : ''}`}
                  style={{
                    borderColor: "rgb(217, 214, 211)",
                    cursor: "pointer"
                  }}
                >
                  Just submitted
                </td>

                <td className="border p-2 text-right">{js !== 0 ? js : null}</td>
              </tr>
              <tr>
                <td onClick={oaHandler} className="hover:bg-stone-300 bg-stone-200 border p-2 w-4/5 font-bold text-sm" style={{ borderColor: "rgb(217, 214, 211)", cursor: "pointer" }}>
                  Online assessment
                </td>
                <td className="border p-2 text-right">{oa !== 0 ? oa : null}</td>
              </tr>
              <tr>
                <td onClick={viHandler} className="hover:bg-stone-300 bg-stone-200 border p-2 w-4/5 font-bold text-sm" style={{ borderColor: "rgb(217, 214, 211)", cursor: "pointer" }}>
                  Virtual interview
                </td>
                <td className="border p-2 text-right">{vi !== 0 ? vi : null}</td>
              </tr>
              <tr>
                <td onClick={osHandler} className="hover:bg-stone-300 bg-stone-200 border p-2 w-4/5 font-bold text-sm" style={{ borderColor: "rgb(217, 214, 211)", cursor: "pointer" }}>
                  Offer stage
                </td>
                <td className="border p-2 text-right">{os !== 0 ? os : null}</td>
              </tr>
              <tr>
                <td onClick={rjHandler} className="hover:bg-stone-300 bg-stone-200 border p-2 w-4/5 font-bold text-sm" style={{ borderColor: "rgb(217, 214, 211)", cursor: "pointer" }}>
                  Rejected
                </td>
                <td className="border p-2 text-right">{rj !== 0 ? rj : null}</td>
              </tr>
              <tr>
                <td onClick={ttHandler} className="hover:bg-stone-300  bg-stone-200 border p-2 w-4/5 font-bold text-sm" style={{ borderColor: "rgb(217, 214, 211)", cursor: "pointer" }}>
                  Total
                </td>
                <td className="border p-2 text-right">{to !== 0 ? to : null}</td>
              </tr>
            </tbody>
          </table>
        </div>


        <div className="w-3/5 border"  >
          {/* {mystatsPost} */}
          <StaticsTableRight positionStats={positionStats} />
        </div>
      </div>



      <div className="m-2 p-2 md:p-3 bg-white rounded-b-xl border flex flex-col mt-0 border-t-0" style={{ height: "60vh", backgroundColor: "rgb(255,251,240)" }}>

        <div className="h-full ">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default StatisticsTableCard;
