import React from 'react'

const StatisticsTagItem = (props) => {
    return (
            <div className="flex flex-col text-sm mt-1">
                <a className="m-0 whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {props.name}
                </a>
                <a className="m-0 whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {props.details}
                </a>
                <div className="flex items-center pt-0">
                    <div className="w-full bg-gray-200 rounded-lg">
                        <div className="bg-blue-500 rounded-lg h-2" style={{ width:  `${props.progress}%` }}></div>
                    </div>
                    <a className="ml-2">{props.num}</a>
                </div>
            </div>
    )
}

export default StatisticsTagItem