import React from 'react'
import { useState } from 'react';
import { Card, Avatar, Button, TextField } from '@mui/material';
import { useStateContext } from '../contexts/ContextProvider';
const PostReplySecond = ({ item }) => {
    const {server,userId} = useStateContext()
    const [likeNum,setLikeNum]=useState(item.rate);
    const increaseLike=()=>{
        updateLike(item.replyid,item.account,item.post,item.content);
       
    }
    const updateLike = (id,account,post,content) => {
        let formData = {
          post:post, 
          message: id,
          replyaccount:account,
          account:userId,
          original:content,
        };
        fetch(server + "/insertMessageLikes", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                  setLikeNum(likeNum+1);
                } else {
                  setLikeNum(likeNum-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
      }
    function formatTime(time) {
        const now = new Date();
        const time2 = new Date(time);
        const timeDifference = Math.abs(now - time2);
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        if (hoursDifference === 0) {
            return 'just now';
        } else if (hoursDifference === 1) {
            return '1 hour ago';
        } else if (hoursDifference < 24) {
            return `${hoursDifference} hours ago`;
        } else if (hoursDifference < 48) {
            return 'yesterday';
        } else {
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June', 'July',
                'August', 'September', 'October', 'November', 'December'
            ];

            const month = monthNames[time2.getMonth()];
            const day = time2.getDate();
            const year = time2.getFullYear();
            return `on ${month} ${day}, ${year}`;
        }
    }
    const formattedTime = formatTime(item.time);
    return (
        <div className="ml-8 p-2 pl-0 pb-0 pt-0">
            <div className="flex items-center">
                <Avatar alt="User Avatar" src={item.avatar} sx={{ width: 24, height: 24 }} />
                <div className="ml-2 flex-1">
                    <p className="text-sm font-semibold text-gray-700">{item.sender}</p>
                    <p className="text-xs text-gray-400">{formattedTime}</p>
                </div>
                <span className="text-xs text-gray-400">{likeNum}</span>
                <Button variant="text" size="small"
                onClick={increaseLike}>
                    Like
                </Button>
            </div>

            <div className="flex">
                <div className='' style={{
                    width: "24px",
                }}></div>
                <div className='ml-2' style={{
                    flex: 1,
                }}>
                    <p className="text-sm leading-relaxed">
                        {item.content}
                    </p>
                </div>
            </div>

        </div>
    )
}

export default PostReplySecond