import React, { useState } from "react";
import { JobCard,SpecificationJoin } from "../components";
import { useStateContext } from "../contexts/ContextProvider";

const NewGrads = (props) => {
  const { newGradData } = useStateContext();
  const [searchText, setSearchText] = useState("");


  const filteredNewGrads = newGradData.filter((item) =>
    item.Name.toLowerCase().includes(searchText.toLowerCase()) ||
    item.Requirement.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="m-0 p-0 bg-white border-l "
      style={{ display: (props.sid == 1) ? "block" : "none" }}>
      <div className="m-0  flex gap-0 ">

        <div className="h-screen relative border-r w-full md:w-3/5 max-w-600px mr-4 overflow-y-auto">
          <div class="sticky top-0 z-30 bg-white bg-opacity-70 backdrop-blur-lg">
            <div class="flex items-center justify-between px-4 py-2">
              <div class="relative transition  ease-in-out transform   text-gray-700 p-2 rounded-full font-bold">
                NewGrads
              </div>

            </div>
          </div>
          <div className=" p-4 " style={{ maxHeight: "calc(100% - 130px)" }}>
            {filteredNewGrads.map((item, index) => (
              <JobCard
                key={index}
                teamsId={item.teamsId}
                searchText={searchText}
                jobtype={item.type}
                company={item.Name}
                requirement={item.Requirement}
                date={item.Date}
                link={item.Link}
              />
            ))}
          </div>
        </div>




        <div className="w-2/5 max-w-315px mr-4 flex  flex-col  h-screen overflow-auto">
          <div className="w-full ">
            <div className="m-4 p-4 rounded-lg ">
              <input
                type="text"
                className="w-full py-2 px-4 rounded-lg border-2 border-gray-300 focus:outline-none "
                placeholder="Search for newgrads..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              
               <div className="pt-4"><SpecificationJoin/></div>
            </div>
           
          </div>
          
        </div>


      </div>



    </div>
    //   <div className="border shadow-md bg-white" style={{ display: props.sid === 1 ? "block" : "none", height: "100%" }}>
    //   <div className="m-4 p-4 rounded-lg ">
    //     <input
    //       type="text"
    //       className="w-full py-2 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
    //       placeholder="Search for newgrads..."
    //       value={searchText}
    //       onChange={(e) => setSearchText(e.target.value)}
    //     />
    //   </div>

    //   <div className="m-4 p-4 " style={{ maxHeight: "calc(100% - 130px)" }}>
    //     {filteredNewGrads.map((item, index) => (
    //       <JobCard
    //         key={index}
    //         teamsId={item.teamsId}
    //         searchText={searchText}
    //         jobtype={item.type}
    //         company={item.Name}
    //         requirement={item.Requirement}
    //         date={item.Date}
    //         link={item.Link}
    //       />
    //     ))}
    //   </div>
    // </div>
  );
};

export default NewGrads;
