import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useStateContext } from '../contexts/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const StaticsTableTr = (props) => {
    const {mystatsPostName,setMyStatsPostName,mystatsPost,setMyStatsPost,reminderListIV, reminderListOA, setReminderListIV, setReminderListOA, joblog, setJoblog, userId, server } = useStateContext();
    const [selectedStatus, setSelectedStatus] = useState(props.status);
    const [newStatus, setNewStatus] = useState(-1);
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [inputError, setinputError] = useState(true)
    const [round,setRound]=useState(props.round);
    const [roundFlag,setRoundFlag]=useState(false);
    const inputRef = useRef(null);
    const handleDateChange = (event) => {
        const dateValue = event.target.value;
        setSelectedDate(dateValue);
    };
    const handleOpen = () => {
        setSelectedDate(null);
        setOpen(true);
    };
    const handleClose = () => {
        setSelectedDate(null);
        setinputError(true);
        setOpen(false);
    };
    const updateVoteStatsByStatus = (status) => {
        let formData = {
          teams: props.teams,
          status: status,
        };
        fetch(server + "/updateVoteStatsByStatus", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
            } else {
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    useEffect(() => {
        setJoblog((prevJoblog) => {
            const updatedTeams = prevJoblog.map((team) => {
                if (team.teams === props.teams) {
                    return {
                        ...team,
                        status: selectedStatus,
                    };
                }
                return team;
            });
            return updatedTeams;
        });
    }, [selectedStatus])
    useEffect(() => {
        setJoblog((prevJoblog) => {
            const updatedTeams = prevJoblog.map((team) => {
                if (team.teams === props.teams) {
                    return {
                        ...team,
                        round: round,
                    };
                }
                return team;
            });
            return updatedTeams;
        });
    }, [round])
    const updateRound = (status) => {
      
        const currRound=round;
        let formData = {
            account: userId,
            teams: props.teams,
            round:currRound+1,
        };
        setRound(round+1);
        fetch(server + "/updateRound", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                } else {
                }
            })
            .catch((error) => {
            });
    }
    const updateStatus = (status) => {
      
        let formData = {
            account: userId,
            teams: props.teams,
            status: status,
            date:props.date,
        };
        fetch(server + "/updateJoblogStatus", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                } else {
                }
            })
            .catch((error) => {
            });
    }
    const handleStatusChange = async (event) => {
        let nextStatus = event.target.value;
        if (nextStatus >= selectedStatus) {
            if(nextStatus==='2.5'){
                setRoundFlag(true);
                handleOpen();
                return;
            }
            await setNewStatus(nextStatus);
            if (nextStatus === '1' || nextStatus === '2') {
                handleOpen();
            } else {
                updateVoteStatsByStatus(nextStatus)
                updateStatus(nextStatus);
                setSelectedStatus(nextStatus);
                setNewStatus(null);
            }
        }
    };
    const confirmHandler = () => {
        if (selectedDate === null) {
            setRoundFlag(false);
            setinputError(false);
        } else {
            if (newStatus === '1') {
                updateOAList();
            } else {
                updateIVList();
            }
            if(roundFlag){
                updateRound();
            }else{
            updateStatus(newStatus);
            updateVoteStatsByStatus(newStatus)
            setSelectedStatus(newStatus);
            }
            updateReminder();
            setinputError(true);
            setNewStatus(null);
            handleClose();
        }
    }
    const updateOAList = () => {
        const newReminder = {
            account: userId,
            teams: props.teams,
            company: props.company,
            title: props.title,
            type: props.type,
            deadline: selectedDate,
            flag: false,
            flag2: false,
        };
        const updatedOAList = [...reminderListOA, newReminder];
        updatedOAList.sort((a, b) => {
            const now = new Date();
            const timeDiffA = Math.abs(new Date(a.deadline) - now);
            const timeDiffB = Math.abs(new Date(b.deadline) - now);
            const daysLeftA = Math.ceil(timeDiffA / (1000 * 60 * 60 * 24));
            const daysLeftB = Math.ceil(timeDiffB / (1000 * 60 * 60 * 24));
            return daysLeftA - daysLeftB;
        });
        setReminderListOA(updatedOAList);
    };
    const updateIVList = () => {
        const newReminder = {
            account: userId,
            teams: props.teams,
            company: props.company,
            title: props.title,
            type: props.type,
            deadline: selectedDate,
            flag: true,
            flag2: false,
        };
        const updatedIVList = [...reminderListIV, newReminder];
        updatedIVList.sort((a, b) => {
            const now = new Date();
            const timeDiffA = Math.abs(new Date(a.deadline) - now);
            const timeDiffB = Math.abs(new Date(b.deadline) - now);
            const daysLeftA = Math.ceil(timeDiffA / (1000 * 60 * 60 * 24));
            const daysLeftB = Math.ceil(timeDiffB / (1000 * 60 * 60 * 24));
            return daysLeftA - daysLeftB;
        });
        setReminderListIV(updatedIVList);
    };
    const updateReminder = () => {
        let flag = false;
        // iv is true
        if (newStatus === '2') {
            flag = true;
        }

        let formData = {
            account: userId,
            teams: props.teams,
            company: props.company,
            title: props.title,
            type: props.type,
            deadline: selectedDate,
            flag: flag,
            flag2: false
        };
        fetch(server + "/addReminder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                } else {
                }
            })
            .catch((error) => {
            });
    }


    const clickHandler=()=>{
        setMyStatsPost(props.teams)
        setMyStatsPostName(props.company)
    }

    return (
        <tr className="hover:bg-yellow-300 " >
            <td className="border-gray-400 px-4 py-2 cursor-pointer" onClick={clickHandler}>{props.company}</td>
            <td className="border-gray-300 px-4 py-2 text-left">
                <div className="">
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} className="rounded-xl"
                            style={{
                                // backgroundColor:"rgb(189,242,253)",
                            }}>
                            <div className='flex flex-col items-center p-0 m-0'>
                                <h2 className='font-bold mb-2'>Set a deadline for it!</h2>
                                <input
                                    className={`mb-4 rounded-xl font-bold ${inputError ? '' : 'ring-4 ring-red-400'}`}
                                    type='date'
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    ref={inputRef}
                                    min={getFormattedDate(new Date())} 
                                />
                                <Button className='' onClick={confirmHandler}>confirm</Button>
                            </div>

                        </Box>
                    </Modal>

                    <select
                        className="block appearance-none px-2 py-1 text-gray-700 rounded-md focus:outline-none focus:border-blue-500"
                        style={{ backgroundColor: 'rgb(255, 251, 240)' }}
                        value={selectedStatus}
                        onChange={handleStatusChange}
                    >
                        <option value="0" className="bg-red-500 text-white">
                            Submitted
                        </option>
                        <option value="1">Received OA</option>
                        <option value="2">  Scheduled Virtual Interview Round {round === 1 ? "" : round}</option>
                        {selectedStatus == 2  && (
                            <option value="2.5">Scheduled Virtual Interview Round {round + 1}</option>
                        )}
                        <option value="3">Received Offer</option>
                        <option value="4" className="bg-red-500 text-white">
                            Rejected
                        </option>
                    </select>
                </div>
            </td>
            <td className="border-gray-400 px-4 py-2">{props.title}</td>
            <td className="border-gray-400 px-4 py-2">{props.type}</td>
            <td className="border-gray-400 px-4 py-2">{props.date}</td>
        </tr>
    );
};

export default StaticsTableTr;
