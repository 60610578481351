import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useStateContext } from '../contexts/ContextProvider';
import neuImage from '../neu_1.jpg'
const neuUrl = neuImage;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 600 },
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const StyledButton = styled(Button)({
    marginTop: 16,
    marginBottom: 8,
    width: '100%',
    padding: '10px 0',
    backgroundColor: '#d92027', // Northeastern red
    color: 'white',
});

const Neu = (props) => {
    const theme = useTheme();
    const { server, userId } = useStateContext();
    const [uploadedFileUrl, setUploadedFileUrl] = useState(props.myResumeUrl);
    const [uploadedFileName, setUploadedFileName] = useState(props.myResumeName);
    const fileInputRef = useRef(null);


    const handleFileSelect = () => {

        fileInputRef.current.click();
    };


    const handleFileChange = (event) => {

        const file = event.target.files[0];
        if (file) {
            // setUploadedFileName(file.name);
            uploadFileToServer(file);
        }
    };
    const uploadFileToServer = (file) => {

        const formData = new FormData();
        formData.append('file', file);
        formData.append('account', userId);
        fetch(server + '/uploadFileResume', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data != null) {
                    setUploadedFileName(data.filename);
                    setUploadedFileUrl(data.url);
                }
            })
            .catch((error) => {
                // 处理错误
            });
    };
    useEffect(() => {
        setUploadedFileName(props.myResumeName)
        setUploadedFileUrl(props.myResumeUrl)
    }, [props.myResumeName, props.myResumeUrl])

    return (
        <Box sx={style}>
             <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
             Get the resources you need
                </Typography>
            <Typography
                variant="body1"
                sx={{
                    // color: '#d92027', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: '20px',
                    // borderRadius: '10px',
                    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: '"Arial", sans-serif',
                    lineHeight: '1.5',
                }}
            >
                "If you're new to the tech industry job hunt, you've come to the right place! Have your resume revised by <strong>NEU Career Development advisors</strong>, including veterans from Meta and Amazon. Simply submit your resume and receive valuable feedback to stand out in the tech world!"
            </Typography>



            <Box
                component="img"
                src={neuUrl}
                alt="Referral Program Banner"
                sx={{ maxWidth: '100%', borderRadius: 2, mb: 2 }}
            />

            {/* Invitation and reward information */}
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                <strong>Invite Friends and Earn Rewards!</strong>
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                Invite three users to register using your referral code:
            </Typography>
            <Typography variant="body1" sx={{
                textAlign: 'center',
                backgroundColor: 'black', // 浅灰色背景
                color: 'white',             // 深色文字
                paddingLeft: '12px',           // 内边距
                paddingRight: '12px',           // 内边距
                borderRadius: '5px',       // 圆角边框
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)', // 阴影效果
                fontSize: '1.2em',         // 字体大小
                fontWeight: 'bold'         // 字体加粗
            }}>
                <strong>{userId}</strong>
            </Typography>

            <Typography variant="body1" sx={{ textAlign: 'center', }}>
                <strong>Reward:</strong>
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                Get your resume revised by advisors from NEU Career Development.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'rgb(94,204,182)' }}>
                    {props.invitedFriendsCount}/3 Invited
                </Typography>
            </Box>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".pdf"
                onChange={handleFileChange} // 处理文件选择
            />
            {uploadedFileName && (
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Submitted!--
                    <a href={uploadedFileUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>
                        {uploadedFileName}
                     
                    </a>
                </Typography>

            )}

            <button
                className={`mt-4 mb-2 w-full py-2 text-white transition duration-300 
            ${props.invitedFriendsCount < 3 ? 'bg-red-600 cursor-not-allowed hover:bg-red-400' : 'bg-red-600 hover:bg-black'}`}
                onClick={props.invitedFriendsCount >= 3 ? handleFileSelect : null}
            >
                {uploadedFileName ? "Update Resume" : "Upload Resume"}
            </button>
        </Box>
    );
};

export default Neu;
