import React, { Fragment, useState } from 'react';
import { Box, Button, Typography, TextField, Rating, Modal } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useStateContext } from '../contexts/ContextProvider';
import Editor from './Editor';
import { Assessment } from '@mui/icons-material';
const ReminderListItem = ({ item, type }) => {
    const { setPosts,userId, setReminderListOA, setReminderListIV, server } = useStateContext()
    const now = new Date();
    const deadline = new Date(item.deadline);
    const timeDiff = Math.abs(deadline - now);
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    const deleteItemHandler = () => {
        if (!item.flag) {
            setReminderListOA(prevList => prevList.filter(reminder => reminder !== item));
        } else {
            setReminderListIV(prevList => prevList.filter(reminder => reminder !== item));
        }

    };
    const [isVisible, setIsVisible] = useState(true);
    const handleSlideOut = () => {
        setIsVisible(false);
    };
    const updateReminderList = (status) => {
        let formData = {
            uuid: item.uuid,
            post:item.teams,
            type:type,
        };
        fetch(server + "/updateFinishtimeAndFlag2ByUuid", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                } else {
                }
            })
            .catch((error) => {
            });
    }


    let labels = null;
    if (type == 1) {
        labels = {
            1: 'Extremely Challenging',
            2: 'Quite Difficult',
            3: 'Somewhat Challenging',
            4: 'Easy',
            5: 'Super Easy',
        };
    } else if (type == 2) {
        labels = {
            1: 'Very Poor',
            2: 'Poor',
            3: 'Fair',
            4: 'Good',
            5: 'Excellent',
        };
    }

    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }
    const [editorContent, seteditorContent] = useState(null);
    const editChangeHandler = (innerhtml) => {
        seteditorContent(innerhtml);
    }

    const [value, setValue] = useState(3);
    const [hover, setHover] = useState(-1);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: "70vh",
        bgcolor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: 3,
        p: 4,
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        handleSlideOut();
        updateReminderList();
        setTimeout(deleteItemHandler, 200);
    };



    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength - 3) + '...';
    };


    const changeHandler = () => {
        handleOpen();
    }

    const handleShareAno = () => {
        newPost(true)
        handleClose();
    };

    const handleShare = () => {
        newPost(false)
        handleClose();
    };
    const newPost = (anonymous) => {
        
           let tagTemp="Online Assessment";
           if(type==2){
            tagTemp="Interview"
           }
        
        const formData = {
            account: userId,
            company: item.company,
            tag0: tagTemp,
            tag1: "Experience",
            tag2: "Rating",
            title: item.title,
            content: editorContent,
            anonymous: anonymous,
            rating: value,
            jobid:item.teams,
        };
        fetch(server + "/newPost", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if(data!=null){
                        setPosts(prevPosts => [data, ...prevPosts]);
                        
                      }
                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='rounded-xl'
                BackdropComponent={() => (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            pointerEvents: 'auto',
                        }}
                    />
                )}
            >
                <Box sx={style}>
                    <div className="bg-white flex flex-col items-center" style={{ height: '100%' }}>
                        <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold', fontFamily: 'Arial' }}>
                            Share Your Experience
                        </Typography>

                        <Typography variant="body1">
                            {type == 1 ? "How would you rate the difficulty of this Online Assessment?" : "How did you feel about the interview process?"}
                        </Typography>

                        <div className="flex" sx={{ alignItems: 'center', marginBottom: 2 }}>
                            <Rating
                                name="hover-feedback"
                                value={value}
                                precision={1}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                        </div>
                        <div>
                            {value !== null && (
                                <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 2 }}>
                                    {labels[hover !== -1 ? hover : value]}
                                </Typography>
                            )}
                        </div>


                        <div className="flex-grow overflow-auto p-0 m-0 w-full" >
                            <Editor editChangeHandler={editChangeHandler} />
                        </div>
                        <div style={{ marginTop: '1rem' }}>{/* Additional content */}</div>
                        <div className="flex items-end">
                            <button
                                className={`text-white hover:text-white px-4 py-2 rounded-full mr-2 transition-colors duration-300 ease-in-out ${editorContent === null ? 'bg-neutral-500' : 'bg-black'
                                    }`}
                                onClick={handleShareAno}
                                disabled={editorContent == null}
                            >
                                Share Anonymously
                            </button>
                            <button
                                className={`text-white hover:text-white px-4 py-2 rounded-full mr-2 transition-colors duration-300 ease-in-out ${editorContent === null ? 'bg-neutral-500' : 'bg-black'
                                    }`}
                                onClick={handleShare}
                                disabled={editorContent == null}
                            >
                                Share
                            </button>






                        </div>
                    </div>
                </Box>



            </Modal>

            <div className={`transition-transform ${isVisible ? 'translate-x-0' : '-translate-x-full'}`}>
                <li className="flex items-center px-4 py-3 border-b border-gray-300">
                    <input type="radio" className="form-radio h-4 w-4 text-indigo-600" onChange={changeHandler} />
                    <div className="flex flex-col ml-3">
                        <p className="font-semibold text-sm text-gray-800 truncate" title={item.company}>
                            {truncateText(item.company, 25)}
                        </p>
                        <p className="text-sm text-gray-600 truncate" title={item.title}>
                            {truncateText(item.title, 30)}
                        </p>
                    </div>
                    <p className="ml-auto text-base text-gray-600">
                        {daysLeft} <span className="text-sm text-gray-400">days left</span>
                    </p>
                </li>

            </div>
        </Fragment>
    );
};

export default ReminderListItem;



