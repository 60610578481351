import React from 'react';
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { GoBookmark } from "react-icons/go";
import Modal from '@mui/material/Modal';
import PostForm from './PostForm';
import { Link, NavLink } from "react-router-dom";
import PostFormSteper from './PostFormSteper';
import Neu from './Neu';
import { useStateContext } from '../contexts/ContextProvider';
import VideoJS from './VideoJS';
import VideoPlayer from './VideoPlayer';
import logoImage from '../logo.jpg'
const HomePageStack = (props) => {
    // const logoUrl = "https://xyztype.com/uploads/0722/1658097294459711/derived/4d6a701bfa585324d4c174b6d5db1902/northeastern-banner-N.png";
    const logoUrl = logoImage;
    const [invitedFriendsCount,setInvitedFriendsCount]=useState(0);
    const [myResumeName,setMyresumeName]=useState("");
    const [myResumeUrl,setMyresumeUrl]=useState("");
    const neuLink = "https://careers.northeastern.edu/";
    const divStyle = {
        textAlign: 'center',
        backgroundImage: `url(${process.env.PUBLIC_URL}/bg.png)`,
        backgroundSize: 'cover',
    };
    const [newModalOpen, setNewModalOpen] = useState(false);
    const handleNewModalOpen = () => {
        fetchMyResumeName();
        fetchInvitationCount();
        setNewModalOpen(true)
    };
    const handleNewModalClose = () => setNewModalOpen(false);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const playerRef = React.useRef(null);
    const fetchMyResumeName = () => {
        const formData = {
            account: userId,
        };
        fetch(server + "/getMyResumeName", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if(data!=null){
                    setMyresumeName(data.filename);
                    setMyresumeUrl(data.url);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const fetchInvitationCount = () => {
        const formData = {
            account: userId,
        };
        fetch(server + "/countInvited", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if(data>3){
                    data=3;
                }
               setInvitedFriendsCount(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function handleOpenNewPage() {

        const newPage = window.open('', '_blank');

         
        newPage.document.write(`
  <!DOCTYPE html>
  <html>
  <head>
  
    <link href="https://vjs.zencdn.net/8.6.0/video-js.css" rel="stylesheet" />
    <style>
      /* Create a centered container */
      .center-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #000; /* Set background color to black */
        margin: 0;
      }
  
      /* Style the video container */
      #my-video {
        max-width: 100%;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4); /* Add a shadow effect */
      }
  
      /* Style the video player controls */
      .vjs-control-bar {
        background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
      }
  
      /* Style the play button */
      .vjs-big-play-button {
        background: #ff0000; /* Red play button */
      }
  
      /* Style the poster image */
      #my-video .vjs-poster {
        background-size: cover;
      }
  
      /* Style the error message text */
      .vjs-error-display {
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
      }
    </style>
  </head>
  <body>
  <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/877045450?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="intro"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
  </body>
  </html>
  

  
  `);
    }

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: false,
        fluid: true,
        sources: [{
            src: "https://media.w3.org/2010/05/sintel/trailer.mp4",
            type: 'video/mp4'
        }]
    };
    const handlePlayerReady = (player) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
        });

        player.on('dispose', () => {
        });
    };
    useEffect(() => {
        const handleScroll = () => {
            const newScrollPercentage =
                (window.scrollY + window.innerHeight) / document.body.scrollHeight;
            setScrollPercentage(newScrollPercentage);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (scrollPercentage > 0.8) {
            // alert("fetch new data")
        }
    }, [scrollPercentage]);


    const { userId, server, posts, setPosts, trendings, setTrendings } = useStateContext();


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            props.searchByKeywords(props.searchText)
            props.changeSidHandler(11);
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const [postCompany, setPostCompany] = useState("");
    const postCompanyChangeHandler = (value) => {
        setPostCompany(value)
    }
    const [tag0, setTag0] = useState("");
    const tag0ChangeHandler = (value) => {
        setTag0(value)
    }
    const [tag1, setTag1] = useState("");
    const tag1ChangeHandler = (value) => {
        setTag1(value)
    }
    const [tag2, setTag2] = useState("");
    const tag2ChangeHandler = (value) => {
        setTag2(value)
    }
    const [title, setTitle] = useState("x");
    const titleChangeHandler = (value) => {
        setTitle(value)
    }
    const [content, setContent] = useState("");
    const contentChangeHandler = (value) => {
        setContent(value)
    }
    const [anonymous, setAnonymous] = useState(false);


    const anonymousChangeHandler = () => {


        setAnonymous(true);


    }
    const clearAll = () => {
        setPostCompany("");
        setTag0("");
        setTag1("");
        setTag2("");
        setTitle("x");
        setContent("");
        setAnonymous(false);
    }
    const [editorContent, seteditorContent] = useState(null);
    const editChangeHandler = (innerhtml) => {
        seteditorContent(innerhtml);
    }
    const newPost = (value) => {

        const formData = {
            account: userId,
            company: postCompany,
            tag0: tag0,
            tag1: tag1,
            tag2: tag2,
            title: title,
            content: editorContent,
            anonymous: value,
        };
        fetch(server + "/newPost", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (data != null) {
                        setPosts(prevPosts => [data, ...prevPosts]);

                    }
                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Fragment>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='roundex-xl'
            >
                <PostFormSteper editorContent={editorContent} editChangeHandler={editChangeHandler} newPost={newPost} changeAnonymous={anonymousChangeHandler} clear={clearAll} content={content} setContent={contentChangeHandler} title={title} setTitle={titleChangeHandler} tag0={tag0} setTag0={tag0ChangeHandler} tag1={tag1} setTag1={tag1ChangeHandler} tag2={tag2} setTag2={tag2ChangeHandler} company={postCompany} setPostCompany={postCompanyChangeHandler} handleClose={handleClose} />
            </Modal>

            <Modal
                open={newModalOpen}
                onClose={handleNewModalClose}
                aria-labelledby="new-modal-title"
                aria-describedby="new-modal-description"
            >

                <div>
                    <Neu invitedFriendsCount={invitedFriendsCount} myResumeName={myResumeName} myResumeUrl={myResumeUrl}/>
                </div>
            </Modal>



            <div className=" w-full   rounded-md  my-5 mt-0 sticky top-2">

                <div className="border my-2 flex items-center hover:bg-stone-50 p-2 rounded-full">
                    <svg
                        className="w-6 h-6 text-gray-500 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="11" cy="11" r="8" />
                        <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                    <input
                        type="text"
                        placeholder="Search Posts"
                        className="bg-transparent outline-none w-full"
                        value={props.searchText}
                        onChange={(event) => props.setSearchText(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {/*                 
                <div className="hover:bg-stone-50 border rounded-lg p-3">
                    <h1 className="text-2xl font-bold mb-4 font-serif" style={{
                        // color:"rgb(233,71,93)"
                    }}>Trending...</h1>
                    <ul className="space-y-4">
                        <li className="flex items-center space-x-4">

                            <div>
                                <p className="text-gray-700 font-bold">Trending Topic 1</p>
                                <p className="text-xs text-gray-400">5.3K Posts</p>
                            </div>
                        </li>
                        <li className="flex items-center space-x-4">

                            <div>
                                <p className="text-gray-700 font-bold">Trending Topic 2</p>
                                <p className="text-xs text-gray-400">5.3K Posts</p>
                            </div>
                        </li>
                        <li className="flex items-center space-x-4">

                            <div>
                                <p className="text-gray-700 font-bold">Trending Topic 3</p>
                                <p className="text-xs text-gray-400">5.3K Posts</p>
                            </div>
                        </li>
                    </ul>
                    <a href="#" className="text-blue-500 text-sm mt-4">Show More</a>
                </div> */}



                {/* <div className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-lg shadow-lg p-4 text-white space-y-4">
                    <h2 className="text-2xl font-bold">Invite Your Friends</h2>
                    <p>Recommend three friends to register and receive a free resume editing service.</p>
                    <button className="bg-gradient-to-r from-pink-600 via-purple-600 to-blue-600 hover:from-pink-700 hover:via-purple-700 hover:to-blue-700 text-white py-2 px-4 rounded-md font-semibold">
                        Invite Now
                    </button>
                </div> */}

                <div className="flex items-center justify-center m-2">
                    <button className=" bg-blue-400 text-white hover:text-white hover:bg-blue-500  rounded-full font-semibold px-4 py-2 rounded"
                        onClick={handleOpen}>
                        Create Post
                    </button>
                </div>
                <div className="text-white p-4 rounded-lg max-w-sm mb-2" style={{ backgroundColor: 'rgb(28,28,30)' }}>
                    <div className="font-bold text-lg " style={{ color: 'rgb(245, 147, 54)' }}>Trending</div>
                    <div className="">

                        {trendings.length == 0 ? (<div>no data</div>) : trendings.map((item) => (
                            <article className="border-b border-gray-700 py-2">
                                <div className="font-semibold" style={{ color: "rgb(152,151,153)" }}>{item.name}</div>
                                <div className="flex items-center space-x-1">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item.content }}
                                    ></div>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
                <div className="max-w-sm mx-auto   rounded-lg border border-gray-300 shadow-lg overflow-hidden">
                    {/* Logo as Background Section */}
                    <div className="relative bg-black" style={{ height: '60px' }}>
                        <div style={{ background: `url(${logoUrl}) no-repeat center center`, backgroundSize: 'contain', height: '100%' }}></div>
                    </div>

                    {/* Content Section */}
                    <div className="px-6 py-5 text-center">
                        <h2 className="text-xl font-bold text-gray-900 mb-4">Partnership Announcement</h2>
                        <p className="text-gray-800  mb-4">
                            We're thrilled to announce our collaboration with
                            <a href={neuLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:underline mx-1">
                                NEU Career Development Teams
                            </a>!
                            Get free resume revisions from NEU Career Development advisors NOW!
                        </p>
                        <div className="text-gray-700 italic text-sm">Don't miss this exclusive opportunity!</div>
                        <button
                            className=" bg-black text-white hover:text-white hover:bg-red-600 ml-4 rounded-full font-semibold px-4 py-1 rounded"
                            onClick={handleNewModalOpen}
                        >
                            Learn more
                        </button>
                    </div>
                </div>



                {/* <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-10 rounded-xl shadow-xl text-center">
      <p className="text-white text-3xl font-semibold mb-6">Start Your Journey with Our Introduction Video</p>
      <button
        onClick={handleOpenNewPage}
        className="bg-indigo-700 hover:bg-indigo-800 text-white font-medium py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-110"
      >
        Watch Video
      </button>
    </div> */}



            </div>
        </Fragment>
    );
};

export default HomePageStack;
