import React, { useState } from 'react';
import { GoComment, GoBookmark } from "react-icons/go";
import PostReply from './PostReply';
import Button from '@mui/material/Button';
import PostComment from './PostComment';
import { useStateContext } from '../contexts/ContextProvider';
import { BsBookmarkHeartFill } from 'react-icons/bs';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography, TextField, Rating, Modal } from '@mui/material';
import { DateTime } from 'luxon';
import { FcLikePlaceholder } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
const Post = (props) => {
    const { server, userId } = useStateContext();
    const [commentsList, setCommentsList] = useState([]);
    const addComment = (newReply) => {
        setCommentsList([newReply, ...commentsList]);
    }
    const {
        uuid,
        account,
        company,
        tag0,
        tag1,
        tag2,
        title,
        content,
        anonymous,
        time,
        emoji0,
        emoji1,
        emoji2,
        emoji3,
        emoji4,
        flag0,
        flag1,
        flag2,
        flag3,
        comments,
        postuserId,
        bookmark,
        rating,
        mypost,
        likes,
    } = props;
    const ratingTextMap = {
        1: "Extremely Challenging",
        2: "Quite Difficult",
        3: "Somewhat Challenging",
        4: "Easy",
        5: "Super Easy",
    };

    const interviewRatingTextMap = {
        1: "Very Poor",
        2: "Poor",
        3: "Fair",
        4: "Good",
        5: "Excellent",
    };
    const [comentLength, setcomentLength] = useState(comments)
    const [bmark, setBmark] = useState(bookmark);
    const [likemark, setlikemark] = useState(likes);
    const [applauseVotes, setApplauseVotes] = useState(emoji0);
    const [celebrateVotes, setCelebrateVotes] = useState(emoji1);
    const [thinkingVotes, setThinkingVotes] = useState(emoji2);
    const [laughterVotes, setLaughterVotes] = useState(emoji3);
    const [angryVotes, setAngryVotes] = useState(emoji4);
    const comentAddHandler = () => {
        setcomentLength(comentLength + 1);
    }
    const handleApplauseVote = () => {
        updateEmoji(0);
        // setApplauseVotes(applauseVotes + 1);
    };

    const handleCelebrateVote = () => {
        updateEmoji(1);
        setCelebrateVotes(celebrateVotes + 1);
    };

    const handleThinkingVote = () => {
        updateEmoji(2);
        setThinkingVotes(thinkingVotes + 1);
    };

    const handleLaughterVote = () => {
        updateEmoji(3);
        setLaughterVotes(laughterVotes + 1);
    };

    const handleAngryVote = () => {
        updateEmoji(4);
        setAngryVotes(angryVotes + 1);
    };

    // const processHtml = html => {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(html, 'text/html');

    //     const imgElements = doc.querySelectorAll('img'); // Select all <img> elements

    //     imgElements.forEach(imgElement => {
    //         imgElement.style.width = '50%';
    //         imgElement.style.height = 'auto';
    //     });

    //     return doc.documentElement.innerHTML;
    // };
    const processHtml = html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
    
        const imgElements = doc.querySelectorAll('img'); // Select all <img> elements
    
        imgElements.forEach(imgElement => {
            imgElement.style.display = 'block'; // Make sure the image is a block element
            imgElement.style.margin = '0 auto'; // Center the image horizontally
            imgElement.style.maxWidth = '50%'; // Ensure the image scales down while maintaining aspect ratio
            imgElement.style.height = 'auto'; // Allow the height to adjust automatically
        });
    
        return doc.documentElement.innerHTML;
    };
    

    const formattedContent = processHtml(content);
    const maxCharacters = 250;
    const [showFullText, setShowFullText] = useState(false);

    const toggleText = () => {
        setShowFullText(!showFullText);
    };

    function formatTime(time) {
        const now = DateTime.now();
        const time2 = DateTime.fromISO(time);

        const localTime2 = time2.toLocal();

        const timeDifference = Math.abs(now - localTime2);

        const hoursDifference = Math.floor(timeDifference / 60 / 60 / 1000);
        if (hoursDifference === 0) {
            return 'just now';
        } else if (hoursDifference === 1) {
            return '1 hour ago';
        } else if (hoursDifference < 24) {
            return `${hoursDifference} hours ago`;
        } else if (hoursDifference < 48) {
            return 'yesterday';
        } else {
            const month = localTime2.toLocaleString({ month: 'long' });
            const day = localTime2.day;
            const year = localTime2.year;

            return `on ${month} ${day}, ${year}`;
        }
    }

    const likesHandler = () => {
        
        let formData = {
            post: uuid,
            account: userId,
            postuserId: account,
        };
        fetch(server + "/insertPostLikes", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setApplauseVotes(applauseVotes + 1);
                    setlikemark(true);
                } else {
                    setApplauseVotes(applauseVotes - 1);
                    setlikemark(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const bookmarkHandler = () => {
        setBmark(!bmark)
        let formData = {
            post: uuid,
            account: userId,
        };
        fetch(server + "/insertBookmark", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const updateEmoji = (index) => {
        let formData = {
            uuid: uuid,
            postuserId: account,
            account: userId,
            num: index,
        };
        fetch(server + "/updateEmoji", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const formattedTime = formatTime(time);
    const [showCommentBox, setShowCommentBox] = useState(false);

    const getComment = () => {
        let formData = {
            uuid: uuid,
        };
        fetch(server + "/getComment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {

                    const comments = Object.values(data);
                    setCommentsList(comments);
                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleCommentClick = () => {
        getComment();
        setShowCommentBox(!showCommentBox);
    };
    const [secondReplyIndex, setSecondReplyIndex] = useState(-1);
    const replyIndexChangeHandler = (index) => {
        setSecondReplyIndex(index);
    }
    const deleteHandler = () => {
        props.deletePostHandler(uuid);
    }
    const eidtHandler = () => {
        props.editPostHandler(uuid, content);
    }
    return (
        <div className='relative p-0 m-0 border-b hover:bg-stone-50'>
            {mypost != null && <div className="absolute top-0 right-2 flex space-x-2">
                <button onClick={deleteHandler} className="text-gray-500 hover:text-red-400 hover:underline  ">
                    <i className="far fa-trash-alt"></i> Delete
                </button>
                <button onClick={eidtHandler} className="text-gray-500 hover:text-black hover:underline">
                    <i className="fas fa-edit"></i> Edit
                </button>
            </div>}



            <div className='felx flex-col px-7'>
                <div className='mb-2 font-bold'>
                    <span
                        className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
                        style={{
                            backgroundColor: "rgb(63,70,179)",
                        }}
                    >
                        {company}
                    </span>

                    <div className=' flex flex-wrap p-0 m-0'>
                        {tag0 && (
                            <span
                                className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
                                style={{
                                    backgroundColor: "rgb(237,112,170)",
                                }}
                            >
                                {tag0}
                            </span>
                        )}
                        {tag1 && (
                            <span
                                className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
                                style={{
                                    backgroundColor: "rgb(237,112,170)",
                                }}
                            >
                                {tag1}
                            </span>
                        )}
                        {tag2 && (
                            <span
                                className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
                                style={{
                                    backgroundColor: "rgb(237,112,170)",
                                }}
                            >

                                {tag2}
                                {rating != null &&
                                    tag0 === "Online Assessment" && (
                                        <>
                                            :&nbsp;{ratingTextMap[rating]}
                                        </>
                                    )}


                                {rating != null &&
                                    tag0 == "Interview" && (
                                        <>
                                            :&nbsp;{interviewRatingTextMap[rating]}
                                        </>
                                    )}
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex    my-4 mt-0  relative">
                    {/* {rating&&rating!=0?<div className="absolute top-0 right-0 p-2  text-white rounded-bl-md">
                <Rating 
                readOnly
                            name="read-only"
                            value={rating}
                            precision={1}
                            // getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                                // setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                                // setHover(newHover);
                            }}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                </div>:null} */}



                    {/* <div className="flex-shrink-0 mr-4 flex flex-col">
                <div className=" text-sm flex flex-col justify-between">
                        <button
                            className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
                            onClick={handleApplauseVote}
                        >
                            👏
                        </button>
                        <span className="text-center text-gray-600">{applauseVotes}</span>
                        <button
                                className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
                                onClick={handleCelebrateVote}
                        >
                            🎉
                        </button>
                        <span className="text-center text-gray-600">{celebrateVotes}</span>
                        <button
                            className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
                            onClick={handleThinkingVote}
                        >
                            🤔
                        </button>
                        <span className="text-center text-gray-600">{thinkingVotes}</span>
                        <button
                            className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
                            onClick={handleLaughterVote}
                        >
                            🤣
                        </button>
                        <span className="text-center text-gray-600">{laughterVotes}</span>
                        <button
                            className="text-2xl text-red-500 hover:text-red-600 transform hover:scale-150 transition-transform"
                            onClick={handleAngryVote}
                        >
                            😡
                        </button>
                        <span className="text-center text-gray-600">{angryVotes}</span>
                    </div>
                </div> */}
                    <div className="flex-grow flex flex-col justify-between">

                        <div className=' m-0'>
                            <div className="flex items-center justify-between mb-2">
                                <div className="flex items-center">
                                    {props.anonymous ? (
                                        <Avatar alt="User Avatar" />
                                    ) : (
                                        <Avatar alt="User Avatar" src={props.avatar} />
                                    )}
                                    <div className="ml-2">
                                        <p className="font-semibold text-gray-700">
                                            {props.anonymous ? "Anonymous" : props.username}
                                        </p>

                                        <p className="text-xs text-gray-500">Posted {formattedTime}</p>
                                    </div>
                                </div>

                            </div>


                            <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: showFullText ? formattedContent : formattedContent.slice(0, maxCharacters) }}>
                            </p>
                            {!showFullText && formattedContent.length > maxCharacters && (
                                <span
                                    className="text-blue-500 cursor-pointer hover:underline"
                                    onClick={toggleText}
                                >
                                    ...View All
                                </span>
                            )}

                        </div>

                        <div className="mt-6   rounded-lg flex items-center">
                            <button className="flex items-center space-x-2 text-base text-gray-600"
                                onClick={handleCommentClick}>
                                <GoComment />
                                <span>{comentLength} Comments</span>
                            </button>
                            <button onClick={bookmarkHandler} className="ml-4 flex items-center space-x-1 text-base text-gray-600">
                                {bmark ? (
                                    <>
                                        <BsBookmarkHeartFill />
                                        <span>Unbookmark</span>
                                    </>
                                ) : (
                                    <>
                                        <GoBookmark />
                                        <span>Bookmark</span>
                                    </>
                                )}
                            </button>
                            <button
                                // className="ml-4 text-xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
                                className="ml-4 flex items-center space-x-2 text-base text-gray-600"
                                onClick={likesHandler}
                            >
                                {/* <FcLikePlaceholder /> */}
                                {likemark ? (
                                    <>
                                        <FcLike />
                                        <span>{applauseVotes} likes</span>
                                    </>
                                ) : (
                                    <>
                                        <FcLikePlaceholder />
                                        <span>{applauseVotes} likes</span>
                                    </>
                                )}
                                
                            </button>
                        
                        </div>
                        {showCommentBox && (
                            <>
                                <PostComment comentAddHandler={comentAddHandler} uuid={uuid} addComment={addComment} />
                                {commentsList.map((item, index3) => (
                                    <PostReply comentAddHandler={comentAddHandler} post={uuid} secondReplyIndex={secondReplyIndex} replyIndexChangeHandler={replyIndexChangeHandler} index={item.parentReply.replyid} original={item.parentReply.content} key={item.parentReply.replyid} reply={item} />
                                ))}
                            </>
                        )}


                    </div>

                </div>
            </div>
        </div>
    );
};

export default Post;


// import React, { useState } from 'react';
// import { GoComment, GoBookmark } from "react-icons/go";
// import PostReply from './PostReply';
// import Button from '@mui/material/Button';
// import PostComment from './PostComment';
// import { useStateContext } from '../contexts/ContextProvider';
// import { BsBookmarkHeartFill } from 'react-icons/bs';
// import Avatar from '@mui/material/Avatar';
// import StarIcon from '@mui/icons-material/Star';
// import { Box, Typography, TextField, Rating, Modal } from '@mui/material';
// import { DateTime } from 'luxon';
// import { FcLikePlaceholder } from "react-icons/fc";
// import { FcLike } from "react-icons/fc";
// const Post = (props) => {
//     const { server, userId } = useStateContext();
//     const [commentsList, setCommentsList] = useState([]);
//     const addComment = (newReply) => {
//         setCommentsList([newReply, ...commentsList]);
//     }
//     const {
//         uuid,
//         account,
//         company,
//         tag0,
//         tag1,
//         tag2,
//         title,
//         content,
//         anonymous,
//         time,
//         emoji0,
//         emoji1,
//         emoji2,
//         emoji3,
//         emoji4,
//         flag0,
//         flag1,
//         flag2,
//         flag3,
//         comments,
//         postuserId,
//         bookmark,
//         rating,
//         mypost,
//         likes,
//     } = props;
//     const ratingTextMap = {
//         1: "Extremely Challenging",
//         2: "Quite Difficult",
//         3: "Somewhat Challenging",
//         4: "Easy",
//         5: "Super Easy",
//     };

//     const interviewRatingTextMap = {
//         1: "Very Poor",
//         2: "Poor",
//         3: "Fair",
//         4: "Good",
//         5: "Excellent",
//     };
//     const [comentLength, setcomentLength] = useState(comments)
//     const [bmark, setBmark] = useState(bookmark);
//     const [likemark, setlikemark] = useState(likes);
//     const [applauseVotes, setApplauseVotes] = useState(emoji0);
//     const [celebrateVotes, setCelebrateVotes] = useState(emoji1);
//     const [thinkingVotes, setThinkingVotes] = useState(emoji2);
//     const [laughterVotes, setLaughterVotes] = useState(emoji3);
//     const [angryVotes, setAngryVotes] = useState(emoji4);
//     const comentAddHandler = () => {
//         setcomentLength(comentLength + 1);
//     }
//     const handleApplauseVote = () => {
//         updateEmoji(0);
//         // setApplauseVotes(applauseVotes + 1);
//     };

//     const handleCelebrateVote = () => {
//         updateEmoji(1);
//         setCelebrateVotes(celebrateVotes + 1);
//     };

//     const handleThinkingVote = () => {
//         updateEmoji(2);
//         setThinkingVotes(thinkingVotes + 1);
//     };

//     const handleLaughterVote = () => {
//         updateEmoji(3);
//         setLaughterVotes(laughterVotes + 1);
//     };

//     const handleAngryVote = () => {
//         updateEmoji(4);
//         setAngryVotes(angryVotes + 1);
//     };

//     // const processHtml = html => {
//     //     const parser = new DOMParser();
//     //     const doc = parser.parseFromString(html, 'text/html');

//     //     const imgElements = doc.querySelectorAll('img'); // Select all <img> elements

//     //     imgElements.forEach(imgElement => {
//     //         imgElement.style.width = '50%';
//     //         imgElement.style.height = 'auto';
//     //     });

//     //     return doc.documentElement.innerHTML;
//     // };
//     const processHtml = html => {
//         const parser = new DOMParser();
//         const doc = parser.parseFromString(html, 'text/html');

//         const imgElements = doc.querySelectorAll('img'); // Select all <img> elements

//         imgElements.forEach(imgElement => {
//             imgElement.style.display = 'block'; // Make sure the image is a block element
//             imgElement.style.margin = '0 auto'; // Center the image horizontally
//             imgElement.style.maxWidth = '50%'; // Ensure the image scales down while maintaining aspect ratio
//             imgElement.style.height = 'auto'; // Allow the height to adjust automatically
//         });

//         return doc.documentElement.innerHTML;
//     };


//     const formattedContent = processHtml(content);
//     const maxWords = 100;
//     const [showFullText, setShowFullText] = useState(false);

//     const toggleText = () => {
//         setShowFullText(!showFullText);
//     };
//     const displayContent = showFullText
//         ? formattedContent
//         : formattedContent.split(' ').slice(0, maxWords).join(' ');

//     function formatTime(time) {
//         const now = DateTime.now();
//         const time2 = DateTime.fromISO(time);

//         const localTime2 = time2.toLocal();

//         const timeDifference = Math.abs(now - localTime2);

//         const hoursDifference = Math.floor(timeDifference / 60 / 60 / 1000);
//         if (hoursDifference === 0) {
//             return 'just now';
//         } else if (hoursDifference === 1) {
//             return '1 hour ago';
//         } else if (hoursDifference < 24) {
//             return `${hoursDifference} hours ago`;
//         } else if (hoursDifference < 48) {
//             return 'yesterday';
//         } else {
//             const month = localTime2.toLocaleString({ month: 'long' });
//             const day = localTime2.day;
//             const year = localTime2.year;

//             return `on ${month} ${day}, ${year}`;
//         }
//     }

//     const likesHandler = () => {

//         let formData = {
//             post: uuid,
//             account: userId,
//             postuserId: account,
//         };
//         fetch(server + "/insertPostLikes", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(formData),
//         })
//             .then((response) => response.json())
//             .then((data) => {
//                 if (data) {
//                     setApplauseVotes(applauseVotes + 1);
//                     setlikemark(true);
//                 } else {
//                     setApplauseVotes(applauseVotes - 1);
//                     setlikemark(false);
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }
//     const bookmarkHandler = () => {
//         setBmark(!bmark)
//         let formData = {
//             post: uuid,
//             account: userId,
//         };
//         fetch(server + "/insertBookmark", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(formData),
//         })
//             .then((response) => response.json())
//             .then((data) => {
//                 if (data) {
//                 } else {
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }

//     const updateEmoji = (index) => {
//         let formData = {
//             uuid: uuid,
//             postuserId: account,
//             account: userId,
//             num: index,
//         };
//         fetch(server + "/updateEmoji", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(formData),
//         })
//             .then((response) => response.json())
//             .then((data) => {
//                 if (data) {
//                 } else {
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }

//     const formattedTime = formatTime(time);
//     const [showCommentBox, setShowCommentBox] = useState(false);

//     const getComment = () => {
//         let formData = {
//             uuid: uuid,
//         };
//         fetch(server + "/getComment", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(formData),
//         })
//             .then((response) => response.json())
//             .then((data) => {
//                 if (data) {

//                     const comments = Object.values(data);
//                     setCommentsList(comments);
//                 } else {

//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }
//     const handleCommentClick = () => {
//         getComment();
//         setShowCommentBox(!showCommentBox);
//     };
//     const [secondReplyIndex, setSecondReplyIndex] = useState(-1);
//     const replyIndexChangeHandler = (index) => {
//         setSecondReplyIndex(index);
//     }
//     const deleteHandler = () => {
//         props.deletePostHandler(uuid);
//     }
//     const eidtHandler = () => {
//         props.editPostHandler(uuid, content);
//     }
//     return (
//         <div className='relative p-0 m-0 border-b hover:bg-stone-50'>
//             {mypost != null && <div className="absolute top-0 right-2 flex space-x-2">
//                 <button onClick={deleteHandler} className="text-gray-500 hover:text-red-400 hover:underline  ">
//                     <i className="far fa-trash-alt"></i> Delete
//                 </button>
//                 <button onClick={eidtHandler} className="text-gray-500 hover:text-black hover:underline">
//                     <i className="fas fa-edit"></i> Edit
//                 </button>
//             </div>}



//             <div className='felx flex-col px-7'>
//                 <div className='mb-2 font-bold'>
//                     <span
//                         className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
//                         style={{
//                             backgroundColor: "rgb(63,70,179)",
//                         }}
//                     >
//                         {company}
//                     </span>

//                     <div className=' flex flex-wrap p-0 m-0'>
//                         {tag0 && (
//                             <span
//                                 className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
//                                 style={{
//                                     backgroundColor: "rgb(237,112,170)",
//                                 }}
//                             >
//                                 {tag0}
//                             </span>
//                         )}
//                         {tag1 && (
//                             <span
//                                 className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
//                                 style={{
//                                     backgroundColor: "rgb(237,112,170)",
//                                 }}
//                             >
//                                 {tag1}
//                             </span>
//                         )}
//                         {tag2 && (
//                             <span
//                                 className="text-xs inline-block bg-gray-300 text-white py-0 px-2 rounded-full mr-2"
//                                 style={{
//                                     backgroundColor: "rgb(237,112,170)",
//                                 }}
//                             >

//                                 {tag2}
//                                 {rating != null &&
//                                     tag0 === "Online Assessment" && (
//                                         <>
//                                             :&nbsp;{ratingTextMap[rating]}
//                                         </>
//                                     )}


//                                 {rating != null &&
//                                     tag0 == "Interview" && (
//                                         <>
//                                             :&nbsp;{interviewRatingTextMap[rating]}
//                                         </>
//                                     )}
//                             </span>
//                         )}
//                     </div>
//                 </div>
//                 <div className="flex    my-4 mt-0  relative">
//                     {/* {rating&&rating!=0?<div className="absolute top-0 right-0 p-2  text-white rounded-bl-md">
//                 <Rating 
//                 readOnly
//                             name="read-only"
//                             value={rating}
//                             precision={1}
//                             // getLabelText={getLabelText}
//                             onChange={(event, newValue) => {
//                                 // setValue(newValue);
//                             }}
//                             onChangeActive={(event, newHover) => {
//                                 // setHover(newHover);
//                             }}
//                             emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
//                         />
//                 </div>:null} */}



//                     {/* <div className="flex-shrink-0 mr-4 flex flex-col">
//                 <div className=" text-sm flex flex-col justify-between">
//                         <button
//                             className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
//                             onClick={handleApplauseVote}
//                         >
//                             👏
//                         </button>
//                         <span className="text-center text-gray-600">{applauseVotes}</span>
//                         <button
//                                 className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
//                                 onClick={handleCelebrateVote}
//                         >
//                             🎉
//                         </button>
//                         <span className="text-center text-gray-600">{celebrateVotes}</span>
//                         <button
//                             className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
//                             onClick={handleThinkingVote}
//                         >
//                             🤔
//                         </button>
//                         <span className="text-center text-gray-600">{thinkingVotes}</span>
//                         <button
//                             className="text-2xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
//                             onClick={handleLaughterVote}
//                         >
//                             🤣
//                         </button>
//                         <span className="text-center text-gray-600">{laughterVotes}</span>
//                         <button
//                             className="text-2xl text-red-500 hover:text-red-600 transform hover:scale-150 transition-transform"
//                             onClick={handleAngryVote}
//                         >
//                             😡
//                         </button>
//                         <span className="text-center text-gray-600">{angryVotes}</span>
//                     </div>
//                 </div> */}
//                     <div className="flex-grow flex flex-col justify-between">

//                         <div className=' m-0'>
//                             <div className="flex items-center justify-between mb-2">
//                                 <div className="flex items-center">
//                                     {props.anonymous ? (
//                                         <Avatar alt="User Avatar" />
//                                     ) : (
//                                         <Avatar alt="User Avatar" src={props.avatar} />
//                                     )}
//                                     <div className="ml-2">
//                                         <p className="font-semibold text-gray-700">
//                                             {props.anonymous ? "Anonymous" : props.username}
//                                         </p>

//                                         <p className="text-xs text-gray-500">Posted {formattedTime}</p>
//                                     </div>
//                                 </div>

//                             </div>


//                             <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: showFullText ? formattedContent: displayContent }}>
//                             </p>

//                             {!showFullText && formattedContent.split(' ').length > maxWords && (
//                                 <span
//                                     className="text-blue-500 cursor-pointer hover:underline"
//                                     onClick={() => setShowFullText(true)}
//                                 >
//                                     ...show more
//                                 </span>
                                
//                             )}

//                         </div>

//                         <div className="mt-6   rounded-lg flex items-center">
//                             <button className="flex items-center space-x-2 text-base text-gray-600"
//                                 onClick={handleCommentClick}>
//                                 <GoComment />
//                                 <span>{comentLength} Comments</span>
//                             </button>
//                             <button onClick={bookmarkHandler} className="ml-4 flex items-center space-x-1 text-base text-gray-600">
//                                 {bmark ? (
//                                     <>
//                                         <BsBookmarkHeartFill />
//                                         <span>Unbookmark</span>
//                                     </>
//                                 ) : (
//                                     <>
//                                         <GoBookmark />
//                                         <span>Bookmark</span>
//                                     </>
//                                 )}
//                             </button>
//                             <button
//                                 // className="ml-4 text-xl text-yellow-500 hover:text-yellow-600 transform hover:scale-150 transition-transform"
//                                 className="ml-4 flex items-center space-x-2 text-base text-gray-600"
//                                 onClick={likesHandler}
//                             >
//                                 {/* <FcLikePlaceholder /> */}
//                                 {likemark ? (
//                                     <>
//                                         <FcLike />
//                                         <span>{applauseVotes} likes</span>
//                                     </>
//                                 ) : (
//                                     <>
//                                         <FcLikePlaceholder />
//                                         <span>{applauseVotes} likes</span>
//                                     </>
//                                 )}

//                             </button>

//                         </div>
//                         {showCommentBox && (
//                             <>
//                                 <PostComment comentAddHandler={comentAddHandler} uuid={uuid} addComment={addComment} />
//                                 {commentsList.map((item, index3) => (
//                                     <PostReply comentAddHandler={comentAddHandler} post={uuid} secondReplyIndex={secondReplyIndex} replyIndexChangeHandler={replyIndexChangeHandler} index={item.parentReply.replyid} original={item.parentReply.content} key={item.parentReply.replyid} reply={item} />
//                                 ))}
//                             </>
//                         )}


//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Post;


