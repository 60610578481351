import React, { useEffect, useState } from 'react';
import { Card, Avatar, Button, TextField } from '@mui/material';
import PostCommentSecond from './PostCommentSecond';
import PostReplySecond from './PostReplySecond';
import { useStateContext } from '../contexts/ContextProvider';
import { DateTime } from 'luxon';
const PostReply = ({original,post, index,secondReplyIndex, replyIndexChangeHandler, reply,comentAddHandler }) => {
  const {server,userId} = useStateContext()
  const [secondReply, setSecondReply] = useState(reply.childReplies || []);
  const [likeNum,setLikeNum]=useState(reply.parentReply.rate);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const handleCommentClick = () => {
    replyIndexChangeHandler(index);
    setShowCommentBox(!showCommentBox);
};

const addReply=(newReply)=>{
  // console.log(secondReply);
  // console.log(newReply);
  const updatedSecondReply = [newReply, ...secondReply]; 
  setSecondReply(updatedSecondReply);
}
useEffect(()=>{
  if(secondReplyIndex!=index){
    setShowCommentBox(false);
  }
},[secondReplyIndex])
function formatTime(time) {
  const now = DateTime.now();
  const time2 = DateTime.fromISO(time);

  const localTime2 = time2.toLocal();

  const timeDifference = Math.abs(now - localTime2);

  const hoursDifference = Math.floor(timeDifference / 60 / 60 / 1000);
  if (hoursDifference === 0) {
      return 'just now';
  } else if (hoursDifference === 1) {
      return '1 hour ago';
  } else if (hoursDifference < 24) {
      return `${hoursDifference} hours ago`;
  } else if (hoursDifference < 48) {
      return 'yesterday';
  } else {
      const month = localTime2.toLocaleString({ month: 'long' });
      const day = localTime2.day;
      const year = localTime2.year;

      return `on ${month} ${day}, ${year}`;
  }
}
const updateLike = (id,account,post,content,userId) => {
  
  let formData = {
    // message: id,
    // account:userId,
    post:post, 
    message: id,
    replyaccount:account,
    account:userId,
    original:content,
  };
  fetch(server + "/insertMessageLikes", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
  })
      .then((response) => response.json())
      .then((data) => {
          if (data) {
            setLikeNum(likeNum+1);
          } else {
            setLikeNum(likeNum-1);
          }
      })
      .catch((error) => {
          console.log(error);
      });
}
const likeNumHandler=()=>{
  updateLike(reply.parentReply.replyid,reply.parentReply.account,reply.parentReply.post,original,userId);
  
}
 const formattedTime = formatTime(reply.parentReply.time);
  return (
    <div className=" ">
      {/* Main Reply */}
      <div className="flex items-center  ">
        <Avatar alt="User Avatar" src={reply.parentReply.avatar} sx={{ width: 24, height: 24 }} />
        <div className="ml-2 flex-1">
          <p className="text-sm font-semibold text-gray-700">{reply.parentReply.sender}</p>
          <p className="text-xs text-gray-400">{formattedTime}</p>
        </div>
        <span className="text-xs text-gray-400">{likeNum}</span>
        <Button variant="text" size="small" onClick={likeNumHandler}>
          Like
        </Button>
       
        <Button variant="text" size="small"  onClick={handleCommentClick}>
          Reply
        </Button>
      </div>
      <div className="flex">
        <div className='' style={{
          width:"24px",
        }}></div>
        <div className='ml-2'>
          <p className="text-sm leading-relaxed">
            {reply.parentReply.content}
          </p>
        </div>

      </div>
      {showCommentBox && (
                        <><div className="ml-8 p-2 pl-0 pb-0">
                            <PostCommentSecond comentAddHandler={comentAddHandler}original={original} addReply={addReply} parent={index} post={post} handleCommentClick={handleCommentClick}/>
                            </div>
                        </>
                    )}
      {secondReply && secondReply.length > 0 && secondReply.map((item,index1) => (
        <PostReplySecond updateLike={updateLike} item={item} key={index1}/>
      ))}
    </div>
  );
};

export default PostReply;
