import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Editor from './Editor';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: "70vh",
    bgcolor: '#f2f2f2',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: 3,
    p: 4,
};

const PostForm = (props) => {
    const [anonymous, setAnonymous] = useState(false);

    const handleAnonymousChange = () => {
        setAnonymous(!anonymous);
    };
    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        props.setTitle(newTitle);
    };
    const handleContentChange = (event) => {
        const newContent= event.target.value;
        props.setContent(newContent);
    };
    
    

    return (
        
            <div className='h-full flex flex-col justify-between'>
                {/* <div>
                    <Typography variant="h6" component="h2">
                        Quick Post
                    </Typography>
                </div> */}
                <div className='  h-full'>
                    <Editor editChangeHandler={props.editChangeHandler}/>
                    
                </div>
                
                <div>
                </div>
            </div>

        
    );
}

export default PostForm;
