import React, { useEffect, useState } from 'react'
import { KanbanComponent,ColumnsDirective,ColumnDirective } from '@syncfusion/ej2-react-kanban'
import { kanbanData,kanbanGrid } from '../data/dummy'
import { Header } from '../components'

const Kanban = (props) => {
  const [data,setData]=useState(kanbanData);
  useEffect(() => {
    const timer = setTimeout(() => {
      setData(prevData => {
        const updatedData = [...prevData];
        for (let i = 0; i < updatedData.length; i++) {
          if (updatedData[i].Status === "Open") {
            updatedData[i].Status = "InProgress";
            break;
          }
        }
        return updatedData;
      });
    }, 1000);
  
    return () => {
      clearTimeout(timer);
    };
  }, [data]);
  
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl "
    style={{display:props.sid==4?"block":"none"}}>
    <Header category="" title="Taskbar" />
      <KanbanComponent
      id="kanban"
      dataSource={data}
      cardSettings={{contentField:"Summary",headerField:"Id"}}
      keyField="Status"
      >
        <ColumnsDirective>
        {kanbanGrid.map((item,index)=>(
          <ColumnDirective key={index} {...item}/>
        ))}
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  )
}

export default Kanban